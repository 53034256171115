<template>
  <div class="animated fadeIn">
    <form @submit.prevent="onSubmit">
      <b-row class="justify-content-center">
        <b-col xl="6">
          <b-card>
            <template slot="header">
              <h3 class="card-title">{{ isNew ? $t('labels.backend.categories.titles.create') : $t('labels.backend.categories.titles.edit') }}</h3>
              <div class="card-options">
                <b-form-radio-group
                  buttons button-variant="outline-secondary" v-model="locale" :options="['ar','en']" name="locale"
                ></b-form-radio-group>
              </div>
            </template>
            <b-row class="justify-content-center">
              <b-col xl="12">
                <b-form-group
                  :label="$t('validation.attributes.name_ar')"
                  label-for="name"
                  :label-cols="4"
                  :invalid-feedback="feedback('name.ar') || feedback('name.en')" v-if="locale==='ar'"
                >
                  <b-form-input
                    id="name"
                    name="name"
                    :placeholder="$t('validation.attributes.name_ar')"
                    v-model="model.name.ar"
                    :state="states('name')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  :label="$t('validation.attributes.name_en')"
                  label-for="name"
                  :label-cols="4"
                  :invalid-feedback="feedback('name.ar') || feedback('name.en')" v-else
                >
                  <b-form-input
                    id="name"
                    name="name"
                    :placeholder="$t('validation.attributes.name_en')"
                    v-model="model.name.en"
                    :state="states('name')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  :label="$t('validation.attributes.description_ar')"
                  label-for="description"
                  :label-cols="4"
                  :invalid-feedback="feedback('description.ar') || feedback('description.en')" v-if="locale==='ar'"
                >
                  <b-form-textarea
                    id="description" name="description" :rows="3"
                    :placeholder="$t('validation.attributes.description_ar')"
                    v-model="model.description.ar"
                    :state="states('description')"
                  ></b-form-textarea>
                </b-form-group>
                <b-form-group
                  :label="$t('validation.attributes.description_en')"
                  label-for="description"
                  :label-cols="4"
                  :invalid-feedback="feedback('description.ar') || feedback('description.en')" v-else
                >
                  <b-form-textarea
                    id="description" name="description" :rows="3"
                    :placeholder="$t('validation.attributes.description_en')"
                    v-model="model.description.en"
                    :state="states('description')"
                  ></b-form-textarea>
                </b-form-group>

                <div class="form-group">
                  <b-row>
                    <b-col lg="8" offset-lg="4">
                      <c-switch
                        name="featured"
                        v-model="model.featured"
                        :description="$t('validation.attributes.featured')"
                      ></c-switch>
                    </b-col>
                  </b-row>
                </div>

                <b-form-group
                  :label="$t('validation.attributes.type')"
                  :label-cols="4"
                  :invalid-feedback="feedback('type')"
                  :state="state('type')"
                >
                  <multiselect :options="['Type1', 'Type2', 'Type3']" id="type" name="type" v-model="model.type"
                               :close-on-select="true" :show-labels="false"
                               :placeholder="'-- ' + $t('validation.attributes.type') + ' --'"></multiselect>
                </b-form-group>

                <photo v-model="model.image" :label="$t('validation.attributes.image')" field_name="image" :image_path="model.image_path"></photo>

              </b-col>
            </b-row>

            <b-row slot="footer">
              <b-col md>
                <b-button to="/categories" variant="danger" size="sm">
                  {{ $t('buttons.back') }}
                </b-button>
              </b-col>
              <b-col md>
                <b-button type="submit" variant="success" size="sm" class="float-right"
                          :disabled="pending"
                          v-if="(isNew && this.$app.user.can('create categories')) || this.$app.user.can('edit categories')">
                  {{ isNew ? $t('buttons.create') : $t('buttons.edit') }}
                </b-button>
              </b-col>
            </b-row>
            <atom-spinner
              :animation-duration="1000"
              :size="400"
              :color="'#d3e335'"
              :loading="loading"
              :full="true"
            ></atom-spinner>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import form from '../mixins/form'
import Photo from './components/Photo'
import Multiselect from 'vue-multiselect'

export default {
  name: 'CategoryForm',
  components: { Photo, Multiselect },
  mixins: [form],
  data () {
    return {
      modelName: 'category',
      resourceRoute: 'categories',
      listPath: '/categories',
      locale: 'en',
      model: {
        name: { ar: null, en: null },
        description: { ar: null, en: null },
        featured: false,
        type: null,
        image: null,
        image_path: null
      }
    }
  }
}
</script>
