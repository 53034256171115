export default (app, i18n) => {
  return [
    {
      name: i18n.t('labels.backend.titles.dashboard'),
      url: '/dashboard',
      icon: 'fe fe-trending-up',
      access: true
    },
    {
      divider: true,
      access: true
    },
    {
      title: true,
      name: i18n.t('labels.backend.sidebar.content'),
      access: app.user.can('view tags') || app.user.can('view tags')
    },
    {
      name: i18n.t('labels.backend.categories.titles.menu'),
      url: '/categories',
      icon: 'fa.list',
      access: app.user.can('view categories')
    },
    {
      divider: true,
      access: true
    },
    {
      name: i18n.t('labels.backend.tags.titles.menu'),
      url: '/tags',
      icon: 'fa.tag',
      access: app.user.can('view tags')
    },
    {
      name: i18n.t('labels.backend.links.titles.menu'),
      url: '/links',
      icon: 'fa.link',
      access: app.user.can('view links')
    },
    {
      name: i18n.t('labels.backend.herbs.titles.menu'),
      url: '/herbs',
      icon: 'fa.leaf',
      access: app.user.can('view herbs')
    },
    {
      name: i18n.t('labels.backend.medical_cases.titles.menu'),
      url: '/medical_cases',
      icon: 'fa.user-md',
      access: app.user.can('view medical cases')
    },
    {
      name: i18n.t('labels.backend.medical_treatments.titles.menu'),
      url: '/medical_treatments',
      icon: 'fa.mortar-pestle',
      access: app.user.can('view medical treatments')
    },
    {
      name: i18n.t('labels.backend.advertisements.titles.menu'),
      url: '/advertisements',
      icon: 'fa.ad',
      access: app.user.can('view advertisements')
    },
    {
      name: i18n.t('labels.backend.sliders.titles.menu'),
      url: '/sliders',
      icon: 'fa.tv',
      access: app.user.can('view sliders')
    },
    {
      name: i18n.t('labels.backend.clients.titles.menu'),
      url: '/clients',
      icon: 'fa.user-tie',
      access: app.user.can('view clients')
    },
    {
      name: i18n.t('labels.backend.comments.titles.menu'),
      url: '/comments',
      icon: 'fa.comment-dots',
      access: app.user.can('view comments')
    },
    {
      title: true,
      name: i18n.t('labels.backend.sidebar.access'),
      access: app.user.can('view users') || app.user.can('view roles')
    },
    {
      name: i18n.t('labels.backend.users.titles.menu'),
      url: '/users',
      icon: 'fa.users',
      access: app.user.can('view users')
    },
    {
      name: i18n.t('labels.backend.roles.titles.menu'),
      url: '/roles',
      icon: 'fa.user-shield',
      access: app.user.can('view roles')
    }
  ]
}
