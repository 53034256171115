<template>
  <Header fixed>
    <SidebarToggler class="d-lg-none" display="md" mobile></SidebarToggler>
    <b-link class="navbar-brand" to="/">
      <img class="navbar-brand-full" src="../../../vendor/tabler/brand/logo.png" height="50" alt="HECP">
      <img class="navbar-brand-minimized" src="../../../vendor/tabler/brand/logo-symbol.png" width="50" height="50" alt="HECP">
    </b-link>
    <SidebarToggler class="d-md-down-none" display="lg"></SidebarToggler>
    <b-navbar-nav class="ml-auto">
      <HeaderDropdown right class="px-3 d-none d-md-block" v-if="create_list.length">
        <template slot="header">
          <span class="d-md-down-none">
            <i class="fe fe-plus-circle"></i>&nbsp;&nbsp;{{ $t('labels.add_new') }}
          </span>
        </template>
        <template slot="dropdown">
          <b-dropdown-item v-for="(item,index) in create_list" :to="item.link" :key="index">
            <font-awesome-icon class=" text-primary" v-if="item.icon.match(/^fa\./)" :icon="item.icon.split('.')[1]"></font-awesome-icon>
            <i :class="item.icon +' text-primary'" v-else></i> {{ item.text }}
          </b-dropdown-item>
        </template>
      </HeaderDropdown>
      <!--<HeaderDropdown right class="px-3 d-none d-md-block">-->
      <!--<template slot="header">-->
      <!--<span class="d-md-down-none">{{ $t('labels.language') }}</span>-->
      <!--</template>-->
      <!--<template slot="dropdown">-->
      <!--<b-dropdown-item :key="index" v-for="(locale, index) in this.$app.locales"-->
      <!--:hreflang="index"-->
      <!--:href="`/${index}/${$app.adminPathName}${$route.fullPath}`">-->
      <!--{{ locale.native }}-->
      <!--</b-dropdown-item>-->
      <!--</template>-->
      <!--</HeaderDropdown>-->
      <HeaderDropdown right class="px-3">
        <template slot="header">
          <img src="/images/user.png" class="img-avatar" :alt="$t('labels.user.avatar')">
          <span class="d-md-down-none">
            {{ this.$app.user.name }}
          </span>
        </template>
        <template slot="dropdown">
          <!--<b-dropdown-item to="/admin/user_profile" :href="$app.route('user.account')">-->
          <b-dropdown-item to="/user_profile" v-if="$app.user.can('change password')">
            <i class="fe fe-user"></i>&nbsp;&nbsp;{{ $t('labels.user.profile') }}
          </b-dropdown-item>
          <b-dropdown-item :href="$app.route('logout')">
            <i class="fe fe-log-out"></i>&nbsp;&nbsp;{{ $t('labels.user.logout') }}
          </b-dropdown-item>
        </template>
      </HeaderDropdown>
    </b-navbar-nav>
    <!--<AsideToggler class="d-none d-lg-block"></AsideToggler>-->
  </Header>
</template>

<script>
export default {
  name: 'AppHeader',
  computed: {
    create_list () {
      let list = [
        {
          link: '/herbs/create',
          text: this.$t('labels.backend.new_menu.herb'),
          icon: 'fa.leaf',
          permission: this.$app.user.can('create herbs'),
          mobile: false
        },
        {
          link: '/medical_cases/create',
          text: this.$t('labels.backend.new_menu.medical_case'),
          icon: 'fa.user-md',
          permission: this.$app.user.can('create medical cases'),
          mobile: false
        },
        {
          link: '/medical_treatments/create',
          text: this.$t('labels.backend.new_menu.medical_treatment'),
          icon: 'fa.mortar-pestle',
          permission: this.$app.user.can('create medical treatments'),
          mobile: false
        },
        {
          link: '/links/create',
          text: this.$t('labels.backend.new_menu.reference'),
          icon: 'fa.link',
          permission: this.$app.user.can('create links'),
          mobile: false
        },
        {
          link: '/users/create',
          text: this.$t('labels.backend.new_menu.user'),
          icon: 'fa.user',
          permission: this.$app.user.can('create users'),
          mobile: false
        },
        {
          link: '/roles/create',
          text: this.$t('labels.backend.new_menu.role'),
          icon: 'fa.shield-alt',
          permission: this.$app.user.can('create roles'),
          mobile: false
        }
      ]

      return list.filter((item) => { return item.permission })
    }
  }
}
</script>
