<template>
  <div>
    <form @submit.prevent="onSubmit">
      <b-row class="justify-content-center">
        <b-col xl="12">
          <b-card>
            <template slot="header">
              <h3 class="card-title">{{ isNew ? $t('labels.backend.clients.titles.create') : $t('labels.backend.clients.titles.edit') }}</h3>
              <div class="card-options" v-if="model.coins !== null">
                <h3>
                  <b-badge variant="info">Coins: {{ model.coins }}</b-badge>
                </h3>
              </div>
            </template>

            <b-row class="justify-content-center">
              <b-col xl="6">
                <b-form-group
                  :label="$t('validation.attributes.name')"
                  label-for="name"
                  :label-cols="4"
                  :invalid-feedback="feedback('name')"
                >
                  <b-form-input
                    id="name"
                    name="name"
                    :placeholder="$t('validation.attributes.name')"
                    v-model="model.name"
                    :state="state('name')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  :label="$t('validation.attributes.email')"
                  label-for="email"
                  :label-cols="4"
                  :invalid-feedback="feedback('email')"
                >
                  <b-form-input
                    id="email"
                    name="email"
                    type="email"
                    readonly
                    :placeholder="$t('validation.attributes.email')"
                    v-model="model.email"
                    :state="state('email')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  :label="$t('validation.attributes.mobile')"
                  label-for="mobile"
                  :label-cols="4"
                  :invalid-feedback="feedback('mobile')"
                >
                  <b-form-input
                    id="mobile"
                    name="mobile"
                    type="text"
                    :placeholder="$t('validation.attributes.mobile')"
                    v-model="model.mobile"
                    :state="state('mobile')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  :label="$t('validation.attributes.about')"
                  label-for="about"
                  :label-cols="4"
                  :invalid-feedback="feedback('about')"
                >
                  <b-form-textarea
                    id="about" name="about" :rows="3"
                    :placeholder="$t('validation.attributes.about')"
                    v-model="model.about"
                    :state="state('about') || state('about')"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group
                  :label="$t('validation.attributes.password')"
                  label-for="password"
                  :label-cols="4"
                  :invalid-feedback="feedback('password')"
                >
                  <b-form-input
                    id="password"
                    name="password"
                    type="password"
                    :placeholder="$t('validation.attributes.password')"
                    v-model="model.password"
                    :state="state('password')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  :label="$t('validation.attributes.password_confirmation')"
                  label-for="password_confirmation"
                  :label-cols="4"
                  :invalid-feedback="feedback('password_confirmation')"
                >
                  <b-form-input
                    id="password_confirmation"
                    name="password_confirmation"
                    type="password"
                    :placeholder="$t('validation.attributes.password_confirmation')"
                    v-model="model.password_confirmation"
                    :state="state('password_confirmation')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  :label="$t('validation.attributes.subscription')"
                  :label-cols="4"
                  :invalid-feedback="feedback('subscription')"
                  :state="state('subscription')"
                >
                  <b-form-radio-group
                    class="mr-2"
                    buttons button-variant="outline-primary"
                    v-model="model.subscription"
                  >
                    <b-form-radio
                      v-for="(subscription, index) in Object.keys(subscriptions)"
                      :value="subscription" :key="index"
                      button-variant="outline-primary"
                    >{{ subscriptions[subscription] }}</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>

              </b-col>
              <b-col xl="6">

                <b-form-group
                  :label="$t('validation.attributes.language')"
                  label-for="language"
                  :label-cols="4"
                  :invalid-feedback="feedback('language')"
                >
                  <b-form-input
                    id="language"
                    name="language"
                    type="text"
                    :placeholder="$t('validation.attributes.language')"
                    v-model="model.language"
                    :state="state('language')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  :label="$t('validation.attributes.country')"
                  label-for="country"
                  :label-cols="4"
                  :invalid-feedback="feedback('country')"
                >
                  <b-form-input
                    id="country"
                    name="country"
                    type="text"
                    :placeholder="$t('validation.attributes.country')"
                    v-model="model.country"
                    :state="state('country')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  :label="$t('validation.attributes.notification_token')"
                  label-for=""
                  :label-cols="4"
                  :invalid-feedback="feedback('notification_token')"
                >
                  <b-form-input
                    id="notification_token"
                    name="notification_token"
                    type="text"
                    :placeholder="$t('validation.attributes.notification_token')"
                    v-model="model.notification_token"
                    :state="state('notification_token')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  :label="$t('validation.attributes.date_of_birth')"
                  label-for="date_of_birth"
                  :label-cols="4"
                  :invalid-feedback="feedback('dob')"
                  :state="state('dob')"
                >
                  <b-input-group>
                    <p-datetimepicker
                      :config="config"
                      id="date_of_birth"
                      name="date_of_birth"
                      v-model="model.date_of_birth"
                    ></p-datetimepicker>
                    <b-input-group-append>
                      <b-input-group-text data-toggle>
                        <i class="fe fe-calendar"></i>
                      </b-input-group-text>
                      <b-input-group-text data-clear>
                        <i class="fe fe-x-circle"></i>
                      </b-input-group-text>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>

                <b-form-group
                  :label="$t('validation.attributes.join_date')"
                  label-for="join_date"
                  :label-cols="4"
                  :invalid-feedback="feedback('join_date')"
                >
                  <b-form-input
                    id="join_date"
                    name="join_date"
                    type="text"
                    readonly
                    :placeholder="$t('validation.attributes.join_date')"
                    v-model="model.join_date"
                    :state="state('join_date')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  :label="$t('validation.attributes.platform')"
                  :label-cols="4"
                  :invalid-feedback="feedback('platform')"
                  :state="state('platform')"
                >
                  <multiselect :options="['app', 'web', 'google', 'facebook', 'android', 'ios']" id="type" name="type" v-model="model.platform"
                               :close-on-select="true" :show-labels="false"
                               :placeholder="'-- ' + $t('validation.attributes.platform') + ' --'"></multiselect>
                </b-form-group>

                <b-form-group
                  :label="$t('validation.attributes.gender')"
                  :label-cols="4"
                  :invalid-feedback="feedback('gender')"
                  :state="state('gender')"
                >
                  <b-form-radio-group
                    class="mr-2"
                    buttons button-variant="outline-primary"
                    v-model="model.gender"
                  >
                    <b-form-radio
                      v-for="(gender, index) in [1,2,3]"
                      :value="gender" :key="index"
                      button-variant="outline-primary"
                    >{{ genders[gender] }}</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>

              </b-col>
              <b-col xl="12">
                <b-row class="text-center mt-4">
                  <div class="form-group col-3">
                    <c-switch
                      name="confirmed"
                      v-model="model.confirmed"
                      :description="$t('validation.attributes.confirmed')"
                    ></c-switch>
                  </div>
                  <div class="form-group col-3">
                    <c-switch
                      name="active"
                      v-model="model.active"
                      :description="$t('validation.attributes.active')"
                    ></c-switch>
                  </div>
                  <div class="form-group col-3">
                    <c-switch
                      name="with_notification"
                      v-model="model.with_notification"
                      :description="$t('validation.attributes.with_notification')"
                    ></c-switch>
                  </div>
                  <div class="form-group col-3">
                    <c-switch
                      name="subscribed"
                      v-model="model.subscribed"
                      :description="$t('validation.attributes.subscribed')"
                    ></c-switch>
                  </div>
                </b-row>
              </b-col>
            </b-row>

            <b-row slot="footer">
              <b-col>
                <b-button to="/clients" exact variant="danger" size="sm">
                  {{ $t('buttons.back') }}
                </b-button>
              </b-col>
              <b-col>
                <b-button type="submit" variant="success" size="sm" class="float-right"
                          :disabled="pending"
                          v-if="isNew || this.$app.user.can('edit clients')">
                  {{ isNew ? $t('buttons.create') : $t('buttons.edit') }}
                </b-button>
              </b-col>
            </b-row>
            <atom-spinner
              :animation-duration="1000"
              :size="400"
              :color="'#d3e335'"
              :loading="loading"
              :full="true"
            ></atom-spinner>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import form from '../mixins/form'
import Multiselect from 'vue-multiselect'

export default {
  name: 'ClientForm',
  components: { Multiselect },
  mixins: [form],
  data () {
    return {
      roles: [],
      modelName: 'client',
      resourceRoute: 'clients',
      listPath: '/clients',
      config: {
        wrap: true,
        allowInput: true
      },
      model: {
        name: null,
        email: null,
        mobile: null,
        active: true,
        confirmed: null,
        password: null,
        confirm_password: null,
        about: null,
        date_of_birth: null,
        join_date: null,
        platform: null,
        gender: null,
        subscribed: null,
        with_notification: null,
        country: null,
        language: null,
        notification_token: null,
        class: 'client',
        coins: null,
        subscription: null
      },
      genders: {
        1: 'Male',
        2: 'Female',
        3: 'Prefer Not To Say'
      },
      subscriptions: {
        'MERCHANT': 'Merchant',
        // 'PREMIUM_BASIC': 'PREMIUM BASIC',
        'PREMIUM_ADVANCED': 'PREMIUM ADVANCED'
      }
    }
  }
}
</script>
