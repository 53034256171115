<template>
  <div class="animated fadeIn">
    <b-card>
      <template slot="header">
        <h3 class="card-title">{{ $t('labels.backend.clients.titles.index') }}</h3>
        <div class="card-options">
          <b-btn
            v-b-toggle.filter
            variant="primary"
            class="mr-1"
            size="sm"
            v-b-tooltip.hover
            :title="$t('buttons.show_hide_filters')"
          >
            <font-awesome-icon icon="filter"></font-awesome-icon>
          </b-btn>
        </div>
      </template>
      <b-datatable ref="datasource"
                   @context-changed="onContextChanged"
                   search-route="clients.search"
                   export-route="clients.export"
                   export-data
                   :extra-search-criteria="extraSearchCriteria"
                   delete-route="clients.destroy">extra
        <template slot="extra_filters">
          <b-col>
            <b-collapse id="filter" class="mt-2">
              <b-row>
                <b-col class="form-group">
                  <c-switch
                    name="confirmed"
                    v-model="extraSearchCriteria.confirmed"
                    @change="onContextChangedWithPage"
                    :description="$t('validation.attributes.confirmed')"
                  ></c-switch>
                </b-col>
                <b-col class="form-group">
                  <c-switch
                    name="active"
                    v-model="extraSearchCriteria.active"
                    @change="onContextChangedWithPage"
                    :description="$t('validation.attributes.active')"
                  ></c-switch>
                </b-col>
                <b-col class="form-group">
                  <c-switch
                    name="with_notification"
                    v-model="extraSearchCriteria.with_notification"
                    @change="onContextChangedWithPage"
                    :description="$t('validation.attributes.with_notification')"
                  ></c-switch>
                </b-col>
                <b-col class="form-group">
                  <c-switch
                    name="subscribed"
                    v-model="extraSearchCriteria.subscribed"
                    @change="onContextChangedWithPage"
                    :description="$t('validation.attributes.subscribed')"
                  ></c-switch>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    :label="$t('validation.attributes.gender')"
                    label-for="gender"
                  >
                    <b-form-checkbox-group
                      id="type"
                      v-model="extraSearchCriteria.genders"
                      buttons
                      button-variant="outline-primary"
                      @input="onContextChangedWithPage"
                    >
                      <b-form-checkbox
                        :value="gender.id"
                        v-for="(gender, index) in genders"
                        :key="index"
                      >
                        {{
                          gender.label
                        }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group
                    :label="$t('validation.attributes.platform')"
                    label-for="platform"
                  >
                    <b-form-checkbox-group
                      id="type"
                      v-model="extraSearchCriteria.platforms"
                      buttons
                      button-variant="outline-primary"
                      @input="onContextChangedWithPage"
                    >
                      <b-form-checkbox
                        :value="platform"
                        v-for="(platform, index) in platforms"
                        :key="index"
                      >
                        {{
                          platform
                        }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    :label="$t('validation.attributes.language')"
                    label-for="language"
                  >
                    <multiselect
                      v-model="extraSearchCriteria.languages"
                      :options="languages"
                      @input="onContextChangedWithPage"
                      :multiple="true"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      :placeholder="
                        '-- ' + $t('validation.attributes.language') + ' --'
                      "
                    ></multiselect>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    :label="$t('validation.attributes.country')"
                    label-for="country"
                  >
                    <multiselect
                      v-model="extraSearchCriteria.countries"
                      :options="countries"
                      @input="onContextChangedWithPage"
                      :multiple="true"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      :placeholder="
                        '-- ' + $t('validation.attributes.country') + ' --'
                      "
                    ></multiselect>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-collapse>
          </b-col>
        </template>

        <b-table ref="datatable"
                 striped
                 bordered
                 show-empty
                 responsive
                 no-local-sorting
                 :empty-text="$t('labels.datatables.no_results')"
                 :empty-filtered-text="$t('labels.datatables.no_matched_results')"
                 :fields="fields"
                 :items="dataLoadProvider"
                 sort-by="id"
                 :sort-desc="true"
                 :busy.sync="isBusy"
        >
          <template slot="name" slot-scope="row">
            <router-link v-if="row.item.can_edit" :to="`/clients/${row.item.id}/edit`" v-text="row.value"></router-link>
            <span v-else v-text="row.value"></span>
          </template>
          <template slot="confirmed" slot-scope="row">
            <b-badge :variant="row.value ? 'success' : 'danger'">{{ row.value ? $t('labels.yes') : $t('labels.no') }}</b-badge>
          </template>
          <template slot="subscribed" slot-scope="row">
            <b-badge :variant="row.value ? 'success' : 'danger'">{{ row.value ? $t('labels.yes') : $t('labels.no') }}</b-badge>
          </template>
          <template slot="with_notification" slot-scope="row">
            <b-badge :variant="row.value ? 'success' : 'danger'">{{ row.value ? $t('labels.yes') : $t('labels.no') }}</b-badge>
          </template>
          <template slot="active" slot-scope="row">
            <c-switch v-if="row.item.can_edit" type="text" variant="primary" on="On" off="Off" :checked="row.value" @change="onActiveToggle(row.item.id)"></c-switch>
          </template>
          <template slot="actions" slot-scope="row">
            <b-button v-if="row.item.can_edit" size="sm" variant="primary" :to="`/clients/${row.item.id}/edit`" v-b-tooltip.hover :title="$t('buttons.edit')" class="mr-1">
              <i class="fe fe-edit"></i>
            </b-button>
            <b-button v-if="row.item.can_delete" size="sm" variant="danger" v-b-tooltip.hover :title="$t('buttons.delete')" @click.stop="onDelete(row.item.id)">
              <i class="fe fe-trash"></i>
            </b-button>
            <b-button v-if="row.item.can_edit" size="sm" variant="info" @click="onResendConfirmation(row.item.id)" v-b-tooltip.hover :title="$t('buttons.resend_confirmation')" class="mr-1">
              <i class="fe fe-mail"></i>
            </b-button>
          </template>
        </b-table>
      </b-datatable>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'

export default {
  name: 'ClientList',
  components: { Multiselect },
  data () {
    return {
      isBusy: false,
      fields: [
        { key: 'id', label: this.$t('validation.attributes.id'), sortable: true },
        { key: 'name', label: this.$t('validation.attributes.name'), sortable: true },
        { key: 'email', label: this.$t('validation.attributes.email'), sortable: true },
        { key: 'confirmed', label: this.$t('validation.attributes.confirmed'), 'class': 'text-center', sortable: true },
        { key: 'active', label: this.$t('validation.attributes.active'), 'class': 'text-center', sortable: true },
        // { key: 'mobile', label: this.$t('validation.attributes.mobile'), 'class': 'text-center', sortable: true },
        // { key: 'date_of_birth', label: this.$t('validation.attributes.date_of_birth'), 'class': 'text-center' },
        { key: 'language', label: this.$t('validation.attributes.language'), 'class': 'text-center', sortable: true },
        { key: 'country', label: this.$t('validation.attributes.country'), 'class': 'text-center', sortable: true },
        // { key: 'with_notification', label: this.$t('validation.attributes.with_notification'), 'class': 'text-center' },
        // { key: 'subscribed', label: this.$t('validation.attributes.subscribed'), 'class': 'text-center' },
        { key: 'join_date', label: this.$t('validation.attributes.join_date'), 'class': 'text-center', sortable: true },
        { key: 'last_access_at', label: this.$t('labels.last_access_at'), 'class': 'text-center', sortable: true },
        // { key: 'platform', label: this.$t('validation.attributes.platform'), 'class': 'text-center' },
        // { key: 'updated_at', label: this.$t('labels.created_at'), 'class': 'text-center', sortable: true },
        { key: 'actions', label: this.$t('labels.actions'), 'class': 'nowrap text-center' }
      ],
      extraSearchCriteria: {
        confirmed: null,
        active: null,
        with_notification: null,
        subscribed: null,
        genders: [],
        countries: [],
        languages: [],
        platforms: []
      },
      platforms: ['app', 'web', 'google', 'facebook', 'android', 'ios'],
      genders: [
        { id: 1, label: 'Male' },
        { id: 2, label: 'Female' },
        { id: 3, label: 'Unknown' }
      ],
      countries: [],
      languages: []
    }
  },
  async created () {
    let { data } = await axios.get(this.$app.route('clients.languages'))
    this.languages = data;
    ({ data } = await axios.get(this.$app.route('clients.countries')))
    this.countries = data
  },
  methods: {
    dataLoadProvider (ctx) {
      return this.$refs.datasource.loadData(ctx.sortBy, ctx.sortDesc)
    },
    onContextChanged () {
      return this.$refs.datatable.refresh()
    },
    onContextChangedWithPage () {
      this.$refs.datasource.currentPage = 1
      this.onContextChanged()
    },
    onDelete (id) {
      this.$refs.datasource.deleteRow({ client: id })
    },
    onActiveToggle (id) {
      axios.post(this.$app.route('users.active', { user: id }))
        .then((response) => {
          this.$app.noty[response.data.status](response.data.message)
        })
        .catch((error) => {
          this.$app.error(error)
        })
    },
    onResendConfirmation (id) {
      this.$refs.datasource.loading = true
      axios.post(this.$app.route('clients.resent_confirmation_code', { client: id }))
        .then((response) => {
          this.$refs.datasource.loading = false
          this.$app.noty[response.data.status](response.data.message)
        })
        .catch((error) => {
          this.$refs.datasource.loading = false
          this.$app.error(error)
        })
    }
  }
}
</script>
