<template>
  <b-row class="image pr-5" style="position: relative">
    <b-button
      class="close"
      size="sm"
      variant="danger"
      v-b-tooltip.hover
      :title="$t('buttons.drop_image')"
      @click="dropImage()"
      v-if="!disabled"
    >
      <i class="fe fe-x"></i>
    </b-button>

    <b-button
      class="close mt-5 mr-1"
      size="md"
      variant="default"
      @click="value.collapse_status = !value.collapse_status"
      v-b-tooltip.hover
      :title="$t('buttons.expand')"
    >
      <font-awesome-icon icon="sort-down"></font-awesome-icon>
    </b-button>

    <b-col xl="12">
      <b-form-group
        :label="$t('validation.attributes.images_info.file')"
        label-class="font-weight-bold"
        :label-cols="3"
        :invalid-feedback="feedback(image_file_field)"
        :state="state(image_file_field)"
        class="file-upload-group required"
      >
        <div v-if="photo_url || value.url" class="preview">
          <img
            class="mr-2 mb-2 img-thumbnail"
            :src="photo_url ? photo_url : value.url"
            alt=""
            width="80%"
            @click="$refs.imagefile.click()"
          >
          <input
            style="display: none"
            type="file"
            :id="image_file_field"
            :name="image_file_field"
            :placeholder="$t('labels.no_file_chosen')"
            :state="state(image_file_field)"
            @change="onFileChange"
            ref="imagefile"
          >
        </div>

        <div class="media" v-if="!value.url || (value.url)">
          <div class="media-body">
            <b-form-file
              :id="image_file_field"
              :name="image_file_field"
              :placeholder="$t('labels.no_file_chosen')"
              v-model="value"
              :state="state(image_file_field)"
              @change="onFileChange"
              :ref="compName + '-' + index"
              v-if="!(photo_url || value.url)"
            ></b-form-file>
            <p class="form-text text-muted">
              {{ $t('labels.descriptions.allowed_image_types') }}
            </p>
          </div>
        </div>
      </b-form-group>
    </b-col>
    <b-col xl="12">
      <b-collapse
        :id="`gallery_image_${index}`"
        v-model="value.collapse_status"
        class="mt-2"
        accordion="my-images"
      >
        <b-row>
          <b-col xl="12">
            <b-form-group
              :label="$t('validation.attributes.images_info.en_title')"
              label-class="font-weight-bold"
              :invalid-feedback="feedback(image_en_title_field)"
              :state="state(image_en_title_field)"
              :label-cols="3"
            >
              <b-form-input
                :id="image_en_title_field"
                :name="image_en_title_field"
                :placeholder="$t('validation.attributes.images_info.en_title')"
                v-model="value.en_title"
                :state="state(image_en_title_field)"
                :plaintext="disabled"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              :label="$t('validation.attributes.images_info.ar_title')"
              label-class="font-weight-bold"
              :invalid-feedback="feedback(image_ar_title_field)"
              :state="state(image_ar_title_field)"
              :label-cols="3"
            >
              <b-form-input
                :id="image_ar_title_field"
                :name="image_ar_title_field"
                :placeholder="$t('validation.attributes.images_info.ar_title')"
                v-model="value.ar_title"
                :state="state(image_ar_title_field)"
                :plaintext="disabled"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              :label="$t('validation.attributes.images_info.link')"
              label-class="font-weight-bold"
              :invalid-feedback="feedback(image_link_field)"
              :state="state(image_link_field)"
              :label-cols="3"
            >
              <b-form-input
                :id="image_link_field"
                :name="image_link_field"
                :placeholder="$t('validation.attributes.images_info.link')"
                v-model="value.link"
                :state="state(image_link_field)"
                :plaintext="disabled"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              :label="$t('validation.attributes.images_info.copyright')"
              label-class="font-weight-bold"
              :invalid-feedback="feedback(image_copyright_field)"
              :state="state(image_copyright_field)"
              :label-cols="3"
            >
              <b-form-input
                :id="image_copyright_field"
                :name="image_copyright_field"
                :placeholder="$t('validation.attributes.images_info.copyright')"
                v-model="value.copyright"
                :state="state(image_copyright_field)"
                :plaintext="disabled"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-collapse>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: 'GalleryImage',
  props: {
    value: {
      default: () => ({
        id: 0,
        en_title: null,
        ar_title: null,
        link: null,
        copyright: null,
        fill: null,
        url: null
      }),
      type: Object
    },
    index: {
      default: 0,
      type: Number
    },
    compName: {
      default: 'file',
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    validation: {
      default: () => null,
      type: Object
    }
  },
  data () {
    return {
      config: {
        wrap: true,
        allowInput: true
      },
      photo_url: null
    }
  },
  computed: {
    image_ar_title_field: function () {
      return 'gallery_images.' + this.index + '.ar_title'
    },
    image_en_title_field: function () {
      return 'gallery_images.' + this.index + '.en_title'
    },
    image_link_field: function () {
      return 'gallery_images.' + this.index + '.link'
    },
    image_copyright_field: function () {
      return 'gallery_images.' + this.index + '.copyright'
    },
    image_file_field: function () {
      return 'gallery_images.' + this.index + '.file'
    }
  },
  methods: {
    updateInput: function () {
      this.$emit('input', {
        id: this.value.id,
        ar_title: this.value.ar_title,
        en_title: this.value.en_title,
        link: this.value.link,
        copyright: this.value.copyright,
        file: this.$refs[`${this.compName}-${this.index}`].$el.firstChild.files
          .length
          ? this.$refs[`${this.compName}-${this.index}`].$el.firstChild.files[0]
          : null,
        url: this.value.url,
        collapse_status: this.value.collapse_status
      })
    },
    onFileChange (e) {
      const file = e.target.files[0]
      this.photo_url = URL.createObjectURL(file)
      this.$emit('input', {
        id: this.value.id,
        ar_title: this.value.ar_title,
        en_title: this.value.en_title,
        link: this.value.link,
        copyright: this.value.copyright,
        file: file,
        url: this.value.url,
        collapse_status: this.value.collapse_status
      })
    },
    dropImage () {
      this.$emit('deleted', { index: this.index })
    },
    feedback (name) {
      if (this.state(name)) {
        let v = this.validation ? this.validation : this.$parent.validation
        return v.errors[name][0]
      }
    },
    state (name) {
      let v = this.validation ? this.validation : this.$parent.validation

      return v.errors !== undefined &&
        v.errors.hasOwnProperty(name)
        ? 'invalid'
        : null
    }
  }
}
</script>

<style scoped>
.btn-group >>> .btn.active {
  z-index: 0 !important;
}
.is-invalid >>> .multiselect__tags {
  border-color: #f86c6b !important;
}
.is-invalid >>> .form-control {
  border-color: #f86c6b !important;
}
.image_link {
  width: 30%;
}
.image {
  background-color: #f0fffd;
  padding-top: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 3px;
  border: 1px solid #e9ecef;
}
.image:hover {
  background-color: #e4fffd;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}
.close {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
