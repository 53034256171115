<template>
  <div class="animated fadeIn">
    <form @submit.prevent="onSubmit">
      <b-row class="justify-content-center">
        <b-col xl="6">
          <b-card>
            <template slot="header">
              <h3 class="card-title">{{ isNew ? $t('labels.backend.tags.titles.create') : $t('labels.backend.tags.titles.edit') }}</h3>
              <div class="card-options">
                <b-form-radio-group
                  buttons button-variant="outline-secondary" v-model="locale" :options="['ar','en']" name="locale"
                ></b-form-radio-group>
              </div>
            </template>
            <b-row class="justify-content-center">
              <b-col xl="12">
                <b-form-group
                  :label="$t('validation.attributes.name_ar')"
                  label-for="name"
                  :label-cols="4"
                  :invalid-feedback="feedback('name.ar') || feedback('name.en')" v-if="locale==='ar'"
                >
                  <b-form-input
                    id="name"
                    name="name"
                    :placeholder="$t('validation.attributes.name_ar')"
                    v-model="model.name.ar"
                    :state="states('name')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  :label="$t('validation.attributes.name_en')"
                  label-for="name"
                  :label-cols="4"
                  :invalid-feedback="feedback('name.ar') || feedback('name.en')" v-else
                >
                  <b-form-input
                    id="name"
                    name="name"
                    :placeholder="$t('validation.attributes.name_en')"
                    v-model="model.name.en"
                    :state="states('name')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  :label="$t('validation.attributes.description_ar')"
                  label-for="description"
                  :label-cols="4"
                  :invalid-feedback="feedback('description.ar') || feedback('description.en')" v-if="locale==='ar'"
                >
                  <b-form-textarea
                    id="description" name="description" :rows="3"
                    :placeholder="$t('validation.attributes.description_ar')"
                    v-model="model.description.ar"
                    :state="states('description')"
                  ></b-form-textarea>
                </b-form-group>
                <b-form-group
                  :label="$t('validation.attributes.description_en')"
                  label-for="description"
                  :label-cols="4"
                  :invalid-feedback="feedback('description.ar') || feedback('description.en')" v-else
                >
                  <b-form-textarea
                    id="description" name="description" :rows="3"
                    :placeholder="$t('validation.attributes.description_en')"
                    v-model="model.description.en"
                    :state="states('description')"
                  ></b-form-textarea>
                </b-form-group>

                <photo v-model="model.icon" :label="$t('validation.attributes.icon')" field_name="icon" :image_path="model.icon_path"></photo>

              </b-col>
            </b-row>

            <b-row slot="footer">
              <b-col md>
                <b-button to="/tags" variant="danger" size="sm">
                  {{ $t('buttons.back') }}
                </b-button>
              </b-col>
              <b-col md>
                <b-button type="submit" variant="success" size="sm" class="float-right"
                          :disabled="pending"
                          v-if="(isNew && this.$app.user.can('create tags')) || this.$app.user.can('edit tags')">
                  {{ isNew ? $t('buttons.create') : $t('buttons.edit') }}
                </b-button>
              </b-col>
            </b-row>
            <atom-spinner
              :animation-duration="1000"
              :size="400"
              :color="'#d3e335'"
              :loading="loading"
              :full="true"
            ></atom-spinner>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import form from '../mixins/form'
import Photo from './components/Photo'

export default {
  name: 'TagForm',
  components: { Photo },
  mixins: [form],
  data () {
    return {
      modelName: 'tag',
      resourceRoute: 'tags',
      listPath: '/tags',
      locale: 'en',
      model: {
        name: { ar: null, en: null },
        description: { ar: null, en: null },
        icon: null,
        icon_path: null
      }
    }
  }
}
</script>
