<template>
  <v-footer fixed class="justify-center">
    <div>
      &copy; {{ new Date().getFullYear() }} HerbsEncyclopedia.info. All rights reserved | Design by <a
        href="https://esteps.my" target="_blank">ESTEPS</a>
    </div>
    <v-fab-transition>
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        fixed
        bottom
        right
        color="green"
        @click="toTop"
      >
        <v-icon>keyboard_arrow_up</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-footer>
</template>
<script>
export default {
  name: 'FeFooter',
  data () {
    return {
      fab: false
    }
  },
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    }

  }
}
</script>
<style scoped>
  /deep/ a:not([href]):not([tabindex]) {
    color: white !important;
  }

  button:focus {
    outline: unset !important;
  }
</style>
