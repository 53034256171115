<template>
  <div class="animated fadeIn">
    <form @submit.prevent="onSubmit">
      <b-row class="justify-content-center">
        <b-col xl="6">
          <b-card>
            <template slot="header">
              <h3 class="card-title">{{ isNew ? $t('labels.backend.links.titles.create') : $t('labels.backend.links.titles.edit') }}</h3>
              <div class="card-options">
                <b-form-radio-group
                  buttons button-variant="outline-secondary" v-model="locale" :options="['ar','en']" name="locale"
                ></b-form-radio-group>
              </div>
            </template>
            <b-row class="justify-content-center">
              <b-col xl="12">
                <b-form-group
                  :label="$t('validation.attributes.title_ar')"
                  label-for="title"
                  :label-cols="3"
                  :invalid-feedback="feedback('title.ar') || feedback('title.en')" v-if="locale==='ar'"

                >
                  <b-form-input
                    id="name"
                    name="name"
                    :placeholder="$t('validation.attributes.title_ar')"
                    v-model="model.title.ar"
                    :state="states('title')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  :label="$t('validation.attributes.title_en')"
                  label-for="title"
                  :label-cols="3"
                  :invalid-feedback="feedback('title.ar') || feedback('title.en')" v-if="locale==='en'"
                >
                  <b-form-input
                    id="name"
                    name="name"
                    :placeholder="$t('validation.attributes.title_en')"
                    v-model="model.title.en"
                    :state="states('title')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  :label="$t('validation.attributes.link')"
                  label-for="link"
                  :label-cols="3"
                  :invalid-feedback="feedback('link')"
                >
                  <b-form-input
                    id="link" name="link"
                    :placeholder="$t('validation.attributes.link')"
                    v-model="model.link"
                    :state="state('link')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row slot="footer">
              <b-col md>
                <b-button to="/links" variant="danger" size="sm">
                  {{ $t('buttons.back') }}
                </b-button>
              </b-col>
              <b-col md>
                <b-button type="submit" variant="success" size="sm" class="float-right"
                          :disabled="pending"
                          v-if="(isNew && this.$app.user.can('create links')) || this.$app.user.can('edit links')">
                  {{ isNew ? $t('buttons.create') : $t('buttons.edit') }}
                </b-button>
              </b-col>
            </b-row>
            <atom-spinner
              :animation-duration="1000"
              :size="400"
              :color="'#d3e335'"
              :loading="loading"
              :full="true"
            ></atom-spinner>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import form from '../mixins/form'

export default {
  name: 'LinkForm',
  mixins: [form],
  data () {
    return {
      modelName: 'link',
      resourceRoute: 'links',
      listPath: '/links',
      locale: 'en',
      model: {
        title: { ar: null, en: null },
        link: null
      }
    }
  }
}
</script>
