<template>
  <div class="app">
    <AppHeader></AppHeader>
    <div class="app-body">
      <Sidebar fixed>
        <div class="sidebar-header">
          <i class="fe fe-user"></i>&nbsp;&nbsp;{{ $app.user.name }}
        </div>
        <SidebarNav :nav-items="nav"></SidebarNav>
        <SidebarFooter></SidebarFooter>
        <SidebarMinimizer></SidebarMinimizer>
      </Sidebar>
      <main class="main">
        <breadcrumb :list="$route.matched"></breadcrumb>
        <div class="container-fluid">
          <router-view :key="$route.name"></router-view>
        </div>
      </main>
      <!--<Aside fixed></Aside>-->
    </div>
    <AppFooter :name="$app.appName" :editor-name="$app.editorName"
               :editor-site-url="$app.editorSiteUrl"></AppFooter>
  </div>
</template>

<script>
import nav from '../_nav'

import AppFooter from '../components/Footer'
import AppHeader from '../components/Header'

export default {
  name: 'Full',
  components: {
    AppHeader,
    AppFooter
  },
  data () {
    return {
      nav: []
    }
  },
  watch: {
    '$route': 'fetchData'
  },
  created () {
    this.initNav()
    this.fetchData()
  },
  methods: {
    initNav () {
      this.nav = nav(
        this.$app,
        this.$i18n,
        this.$store.state.counters.newPostsCount,
        this.$store.state.counters.pendingPostsCount
      )
    },
    async fetchData () {
      await this.$store.dispatch('LOAD_COUNTERS')
      this.initNav()
    }
  }
}
</script>

<style>
  .dropdown-item svg {
    width: 20px;
    margin-right: 10px;
    margin-left: -10px;
    color: rgba(0, 40, 100, 0.12);
    text-align: center;
  }
  .dropdown-item.active svg {
    color: white !important;
  }
  .file-upload-group .invalid-feedback {
    display: block;
  }
  .multiselect--active, .multiselect__content-wrapper {
    z-index: 1030 !important;
  }
  .multiselect .multiselect__tags {
    border-radius: 0px !important;
    border: 1px solid #c2cfd6 !important;
  }
  .custom-file-label {
    overflow: hidden;
    white-space: nowrap;
  }
  .form-fieldset {
    background: #f7fffd;
    border: 1px solid #e9ecef;
    padding: 1rem;
    border-radius: 3px;
    margin-bottom: 1rem;
  }
  .is-invalid .flatpickr-input {
    border-color: #cd201f;
  }
  .is-invalid .multiselect__tags {
      border-color: #f86c6b !important;
  }
</style>
