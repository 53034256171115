<template>
  <div>
    <b-form-group
      :label="$t('validation.attributes.target_type')"
      label-for="target_type"
      :label-cols="4"
      :invalid-feedback="feedback('target.target_type')"
      :state="state('target.target_type')"
    >
      <multiselect :options="Object.keys(target_types)" id="target_type" name="target_type" v-model="value.target_type"
                   :close-on-select="true" :show-labels="false" @input="resetsTarget"
                   :placeholder="'-- ' + $t('validation.attributes.target_type') + ' --'">
        <template slot="singleLabel" slot-scope="props">
          <div class="option__desc">
            <font-awesome-icon :icon="target_types[props.option].icon" style="color: #4CAF50"></font-awesome-icon>
            <span class="option__title">{{ $t(`labels.backend.target_types.${props.option}`) }}</span>
          </div>
        </template>
        <template slot="option" slot-scope="props">
          <div class="option__desc">
            <font-awesome-icon :icon="target_types[props.option].icon" style="color: #4CAF50"></font-awesome-icon>
            <span class="option__title">{{ $t(`labels.backend.target_types.${props.option}`) }}</span>
          </div>
        </template>
      </multiselect>
    </b-form-group>
    <b-form-group
      :label="$t('validation.attributes.target')"
      label-for="target"
      :label-cols="4"
      :invalid-feedback="feedback('target.target')"
      :state="state('target.target')"
      v-if="value.target_type != 'External' && value.target_type != 'Empty'"
    >
      <multiselect v-model="value.target" :options="targets"
                   id="targets" label="name" track-by="id"
                   :placeholder="'-- ' + $t('validation.attributes.target') + ' --'"
                   :searchable="true" :show-labels="false"
                   :loading="isLoading" :internal-search="false" :clear-on-select="true"
                   :close-on-select="true" :options-limit="300"
                   :option-height="100"
                   :show-no-results="false" @search-change="asyncFindTarget">
      </multiselect>
    </b-form-group>
    <b-form-group
      :label="$t('validation.attributes.link')"
      label-for="link"
      :label-cols="4"
      :invalid-feedback="feedback('target.link')"
      v-else-if="value.target_type === 'External'"
    >
      <b-form-input
        id="link" name="link"
        placeholder="Example: http://www.google.com"
        v-model="value.link"
        :state="state('target.link')"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import axios from 'axios'

export default {
  name: 'Target',
  components: { Multiselect },
  props: {
    value: {
      default: () => ({
        target: null,
        target_type: null
      }),
      type: Object
    },
    withExternal: {
      type: Boolean,
      default: false
    },
    withEmpty: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,
      targets: []
    }
  },
  computed: {
    model_target_type () {
      return (this.value.target_type) ? this.target_types[this.value.target_type].link : null
    },
    target_types () {
      let data = {
        Herb: { icon: 'leaf', link: 'herbs' },
        Category: { icon: 'list', link: 'categories' },
        MedicalCase: { icon: 'user-md', link: 'medical_cases' },
        MedicalTreatment: { icon: 'mortar-pestle', link: 'medical_treatments' }
      }
      if (this.withExternal) {
        data.External = { icon: 'external-link-alt', link: null }
      }
      if (this.withEmpty) {
        data.Empty = { icon: 'unlink', link: null }
      }
      return data
    }
  },
  methods: {
    feedback (name) {
      if (this.state(name)) {
        return this.$parent.validation.errors[name][0]
      }
    },
    state (name) {
      return this.$parent.validation.errors !== undefined && this.$parent.validation.errors.hasOwnProperty(name)
        ? 'invalid'
        : null
    },
    async asyncFindTarget (query) {
      if (query.length <= 1) {
        this.targets = []
        return
      }
      this.isLoading = true
      let params = {
        page: 1,
        perPage: 30
      }

      params.search = query

      let { data } = await axios.get(this.$app.route(`${this.model_target_type}.search`), {
        params: params
      })

      let d = data.data
      this.targets = d.map((item) => {
        return {
          id: item.id,
          name: item.name.ar + ' (' + item.name.en + ')'
        }
      })

      this.isLoading = false
    },
    resetsTarget () {
      this.targets = []
      this.value.target = null
    }
  }
}
</script>

<style>
  .option__desc {
    width: unset !important;
  }
</style>
