<template>
  <div class="animated fadeIn">
    <b-card>
      <template slot="header">
        <h3 class="card-title">{{ $t('labels.backend.advertisements.titles.index') }}</h3>
        <div class="card-options" v-if="$app.user.can('create advertisements')">
          <b-form-radio-group
            class="mr-2"
            buttons button-variant="outline-primary"
            @input="onContextChangedWithPage"
            v-model="extraSearchCriteria.type"
          >
            <b-form-radio
              v-for="(type, index) in ['Banner', 'Popup', 'News']"
              :value="type" :key="index"
              button-variant="outline-primary"
            >{{ type.toUpperCase() }}</b-form-radio>
          </b-form-radio-group>
          <b-form-radio-group
            buttons button-variant="outline-secondary" v-model="locale" :options="['ar','en']" name="locale"
          ></b-form-radio-group>
          <b-button class="ml-2" to="/advertisements/create" variant="primary" v-b-tooltip.hover :title="$t('buttons.advertisements.create')" size="sm" v-if="$app.user.can('create advertisements')">
            <i class="fe fe-plus-circle"></i>
          </b-button>
        </div>
      </template>
      <b-datatable ref="datasource"
                   @context-changed="onContextChanged"
                   :extra-search-criteria="extraSearchCriteria"
                   search-route="advertisements.search"
                   delete-route="advertisements.destroy"
                   :length-change="true" :paging="true" :infos="true" :export-data="false">
        <b-table ref="datatable"
                 striped
                 bordered
                 show-empty
                 stacked="md"
                 no-local-sorting
                 :empty-text="$t('labels.datatables.no_results')"
                 :empty-filtered-text="$t('labels.datatables.no_matched_results')"
                 :fields="fields"
                 :items="dataLoadProvider"
                 sort-by="id"
                 :sort-desc="false"
                 :busy.sync="isBusy"
        >
          <template slot="id" slot-scope="row">
            <router-link v-if="row.item.can_edit" :to="`/advertisements/${row.item.id}/edit`" v-text="row.value"></router-link>
            <span v-else v-text="row.value"></span>
          </template>
          <template slot="title" slot-scope="row">
            <span v-text="row.value[locale]"></span>
          </template>
          <template slot="type" slot-scope="row">
            <h4><b-badge variant="info">{{ row.value }}</b-badge></h4>
          </template>
          <template slot="active" slot-scope="row">
            <c-switch v-if="row.item.can_edit" type="text" variant="primary" on="On" off="Off" :checked="row.value" @change="onActiveToggle(row.item.id)"></c-switch>
          </template>
          <template slot="target" slot-scope="row">
            <template v-if="row.item.target && row.item.target.target.id && row.item.target.target_type !== 'External'">
              <font-awesome-icon :icon="target_types[row.item.target.target_type].icon" style="color: #4CAF50"></font-awesome-icon>
              <router-link :to="`/${target_types[row.item.target.target_type].link}/${row.item.target.target.id}/edit`" v-text="row.item.target_name[locale]">
              </router-link>
            </template>
            <template v-else-if="row.item.target && row.item.target.target_type === 'External'">
              <a :href="row.item.target.link" target="_blank">
                <font-awesome-icon icon="external-link-alt" style="color: #4CAF50"></font-awesome-icon>
              </a>
            </template>
          </template>
          <template slot="actions" slot-scope="row">
            <b-button v-if="row.item.can_edit" size="sm" variant="primary" :to="`/advertisements/${row.item.id}/edit`" v-b-tooltip.hover :title="$t('buttons.edit')" class="mr-1">
              <i class="fe fe-edit"></i>
            </b-button>
            <b-button v-if="row.item.can_delete" size="sm" variant="danger" v-b-tooltip.hover :title="$t('buttons.delete')" @click.stop="onDelete(row.item.id)">
              <i class="fe fe-trash"></i>
            </b-button>
          </template>
        </b-table>
      </b-datatable>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AdvertisementList',
  data () {
    return {
      locale: 'en',
      extraSearchCriteria: {
        type: 'Banner'
      },
      isBusy: false,
      target_types: {
        Herb: { icon: 'leaf', link: 'herbs' },
        Category: { icon: 'list', link: 'categories' },
        MedicalCase: { icon: 'user-md', link: 'medical_cases' }
      }
    }
  },
  computed: {
    fields () {
      let fields = [
        { key: 'id', label: this.$t('validation.attributes.id'), class: 'text-center', sortable: true },
        { key: 'title', label: this.$t('validation.attributes.title'), class: 'text-center', sortable: true },
        { key: 'type', label: this.$t('validation.attributes.type'), class: 'text-center', sortable: true },
        { key: 'active', label: this.$t('validation.attributes.active'), 'class': 'text-center' },
        { key: 'target', label: this.$t('validation.attributes.target'), class: 'text-center', sortable: false }
      ]
      if (this.$app.user.can('edit advertisements') || this.$app.user.can('delete advertisements')) {
        fields.push({ key: 'actions', label: this.$t('labels.actions'), 'class': 'nowrap' })
      }
      return fields
    }
  },
  methods: {
    dataLoadProvider (ctx) {
      return this.$refs.datasource.loadData(ctx.sortBy, ctx.sortDesc)
    },
    onContextChanged () {
      return this.$refs.datatable.refresh()
    },
    onContextChangedWithPage () {
      this.$refs.datasource.currentPage = 1
      this.onContextChanged()
    },
    onDelete (id) {
      this.$refs.datasource.deleteRow({ advertisement: id })
    },
    onActiveToggle (id) {
      axios.post(this.$app.route('advertisements.active', { advertisement: id }))
        .then((response) => {
          this.$app.noty[response.data.status](response.data.message)
        })
        .catch((error) => {
          this.$app.error(error)
        })
    }
  }
}
</script>
