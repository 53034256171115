<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-tabs card pills>
        <b-tab
          :title="$t('labels.backend.herbs.titles.info')"
          active
          class="p-0"
        >
          <form @submit.prevent="onSubmit">
            <b-row class="justify-content-center">
              <b-col xl="12" class="py-0">
                <b-card class="my-0">
                  <template slot="header">
                    <h3 class="card-title">{{ isNew ? $t('labels.backend.herbs.titles.create') : $t('labels.backend.herbs.titles.edit') }}</h3>
                    <div class="card-options">
                      <b-form-radio-group
                        buttons button-variant="outline-primary" v-model="locale" :options="['ar','en']" name="locale"
                      ></b-form-radio-group>
                    </div>
                  </template>
                  <b-row class="justify-content-center">
                    <b-col xl="6">
                      <b-form-group
                        :label="$t('validation.attributes.name_ar')"
                        label-class="font-weight-bold"
                        label-for="name"
                        :label-cols="4"
                        :invalid-feedback="feedback('name.ar') || feedback('name.en')" v-if="locale==='ar'"
                        :state="states('name')"
                      >
                        <b-form-input
                          id="name_ar"
                          name="name_ar"
                          :placeholder="$t('validation.attributes.name_ar')"
                          v-model="model.name.ar"
                          :state="states('name')"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        :label="$t('validation.attributes.name_en')"
                        label-class="font-weight-bold"
                        label-for="name"
                        :label-cols="4"
                        :invalid-feedback="feedback('name.ar') || feedback('name.en')" v-if="locale==='en'"
                      >
                        <b-form-input
                          id="name_en"
                          name="name_en"
                          :placeholder="$t('validation.attributes.name_en')"
                          v-model="model.name.en"
                          :state="states('name')"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        :label="$t('validation.attributes.category')"
                        label-class="font-weight-bold"
                        label-for="category"
                        :label-cols="4"
                        :invalid-feedback="feedback('category')"
                        :state="state('category')"
                      >
                        <multiselect v-model="model.category" :options="categories" id="category" name="category"
                                     track-by="id" label="category_name"
                                     :searchable="true" :close-on-select="true" :show-labels="false"
                                     :placeholder="'-- ' + $t('validation.attributes.category') + ' --'"></multiselect>
                      </b-form-group>

                      <b-form-group
                        :label="$t('validation.attributes.tag')"
                        label-class="font-weight-bold"
                        label-for="tag"
                        :label-cols="4"
                        :invalid-feedback="feedback('tags')"
                        :state="state('tags')"
                      >
                        <multiselect v-model="model.tags" :options="tags" id="tags" name="tags"
                                     track-by="id" label="tag_name" :multiple="true"
                                     :searchable="true" :close-on-select="false" :show-labels="false"
                                     :placeholder="'-- ' + $t('validation.attributes.tag') + ' --'"
                                     :loading="isLoading" :internal-search="false" :clear-on-select="true"
                                     :options-limit="300" :option-height="100"
                                     :max-height="600" :show-no-results="true" @search-change="asyncTagHerb">
                        </multiselect>
                      </b-form-group>

                      <b-form-group
                        :label="$t('validation.attributes.link')"
                        label-class="font-weight-bold"
                        label-for="links"
                        :label-cols="4"
                        :invalid-feedback="feedback('links')"
                        :state="state('links')"
                      >
                        <multiselect v-model="model.links" :options="links" id="links" name="links"
                                     track-by="id" label="title" :multiple="true"
                                     :searchable="true" :close-on-select="false" :show-labels="false"
                                     :placeholder="'-- ' + $t('validation.attributes.link') + ' --'"
                                     :loading="isLoading" :internal-search="false" :clear-on-select="true"
                                     :options-limit="300" :option-height="100"
                                     :max-height="600" :show-no-results="true" @search-change="asyncLinkHerb"
                        ></multiselect>
                      </b-form-group>

                      <b-form-group
                        :label="$t('validation.attributes.other_names_ar')"
                        label-class="font-weight-bold"
                        label-for="other_names"
                        :invalid-feedback="feedback('other_names.ar') || feedback('other_names.en')" v-if="locale==='ar'"
                      >
                        <b-form-textarea :rows="3" :max-rows="100"
                                         id="other_names_ar"
                                         name="other_names_ar"
                                         :placeholder="$t('validation.attributes.other_names_ar')"
                                         v-model="model.other_names.ar"
                                         :state="states('other_names')"
                        ></b-form-textarea>
                      </b-form-group>
                      <b-form-group
                        :label="$t('validation.attributes.other_names_en')"
                        label-class="font-weight-bold"
                        label-for="other_names"
                        :invalid-feedback="feedback('other_names.ar') || feedback('other_names.en')" v-if="locale==='en'"
                      >
                        <b-form-textarea :rows="3" :max-rows="100"
                                         id="other_names_en"
                                         name="other_names_en"
                                         :placeholder="$t('validation.attributes.other_names_en')"
                                         v-model="model.other_names.en"
                                         :state="states('other_names')"
                        ></b-form-textarea>
                      </b-form-group>
                      <b-form-group
                        :label="$t('validation.attributes.used_parts_ar')"
                        label-class="font-weight-bold"
                        label-for="used_parts"
                        :invalid-feedback="feedback('used_parts.ar') || feedback('used_parts.en')" v-if="locale==='ar'"
                      >
                        <b-form-textarea
                          id="used_parts_ar" name="used_parts" :rows="3" :max-rows="100"
                          :placeholder="$t('validation.attributes.used_parts_ar')"
                          v-model="model.used_parts.ar"
                          :state="states('used_parts')"
                        ></b-form-textarea>
                      </b-form-group>
                      <b-form-group
                        :label="$t('validation.attributes.used_parts_en')"
                        label-class="font-weight-bold"
                        label-for="used_parts"
                        :invalid-feedback="feedback('used_parts.ar') || feedback('used_parts.en')" v-if="locale==='en'"
                      >
                        <b-form-textarea
                          id="used_parts_en" name="used_parts" :rows="3" :max-rows="100"
                          :placeholder="$t('validation.attributes.used_parts_en')"
                          v-model="model.used_parts.en"
                          :state="states('used_parts')"
                        ></b-form-textarea>
                      </b-form-group>
                      <b-form-group
                        :label="$t('validation.attributes.description_ar')"
                        label-class="font-weight-bold"
                        label-for="description"
                        :invalid-feedback="feedback('description.ar') || feedback('description.en')" v-if="locale==='ar'"
                      >
                        <b-form-textarea
                          id="description_ar" name="description" :rows="3" :max-rows="100"
                          :placeholder="$t('validation.attributes.description_ar')"
                          v-model="model.description.ar"
                          :state="states('description')"
                        ></b-form-textarea>
                      </b-form-group>
                      <b-form-group
                        :label="$t('validation.attributes.description_en')"
                        label-class="font-weight-bold"
                        label-for="description"
                        :invalid-feedback="feedback('description.ar') || feedback('description.en')" v-if="locale==='en'"
                      >
                        <b-form-textarea
                          id="description_en" name="description" :rows="3" :max-rows="100"
                          :placeholder="$t('validation.attributes.description_en')"
                          v-model="model.description.en"
                          :state="states('description')"
                        ></b-form-textarea>
                      </b-form-group>
                      <b-form-group
                        :label="$t('validation.attributes.chemical_composition_ar')"
                        label-class="font-weight-bold"
                        label-for="chemical_composition"
                        :invalid-feedback="feedback('chemical_composition.ar') || feedback('chemical_composition.en')" v-if="locale==='ar'"
                      >
                        <b-form-textarea
                          id="chemical_composition_ar" name="chemical_composition" :rows="3" :max-rows="100"
                          :placeholder="$t('validation.attributes.chemical_composition_ar')"
                          v-model="model.chemical_composition.ar"
                          :state="states('chemical_composition')"
                        ></b-form-textarea>
                      </b-form-group>
                      <b-form-group
                        :label="$t('validation.attributes.chemical_composition_en')"
                        label-class="font-weight-bold"
                        label-for="chemical_composition"
                        :invalid-feedback="feedback('chemical_composition.ar') || feedback('chemical_composition.en')" v-if="locale==='en'"
                      >
                        <b-form-textarea
                          id="chemical_composition_en" name="chemical_composition" :rows="3" :max-rows="100"
                          :placeholder="$t('validation.attributes.chemical_composition_en')"
                          v-model="model.chemical_composition.en"
                          :state="states('chemical_composition')"
                        ></b-form-textarea>
                      </b-form-group>
                      <photo v-model="model.nutritional_value_image" :label="$t('validation.attributes.nutritional_value_image')" field_name="nutritional_value_image" :image_path="model.nutritional_value_image_path" @image_deleted="model.nutritional_value_image_path = null" deletable :validation="validation"></photo>
                      <b-form-group
                        :label="$t('validation.attributes.medical_usages_ar')"
                        label-class="font-weight-bold"
                        label-for="medical_usages"
                        :invalid-feedback="feedback('medical_usages.ar') || feedback('medical_usages.en')" v-if="locale==='ar'"
                      >
                        <b-form-textarea
                          id="medical_usages_ar" name="medical_usages" :rows="3" :max-rows="100"
                          :placeholder="$t('validation.attributes.medical_usages_ar')"
                          v-model="model.medical_usages.ar"
                          :state="states('medical_usages')"
                        ></b-form-textarea>
                      </b-form-group>
                      <b-form-group
                        :label="$t('validation.attributes.medical_usages_en')"
                        label-class="font-weight-bold"
                        label-for="medical_usages"
                        :invalid-feedback="feedback('medical_usages.ar') || feedback('medical_usages.en')" v-if="locale==='en'"
                      >
                        <b-form-textarea
                          id="medical_usages_en" name="medical_usages" :rows="3" :max-rows="100"
                          :placeholder="$t('validation.attributes.medical_usages_en')"
                          v-model="model.medical_usages.en"
                          :state="states('medical_usages')"
                        ></b-form-textarea>
                      </b-form-group>
                      <b-form-group
                        :label="$t('validation.attributes.other_usages_ar')"
                        label-class="font-weight-bold"
                        label-for="other_usages"
                        :invalid-feedback="feedback('other_usages.ar') || feedback('other_usages.en')" v-if="locale==='ar'"
                      >
                        <b-form-textarea
                          id="other_usages_ar" name="other_usages" :rows="3" :max-rows="100"
                          :placeholder="$t('validation.attributes.other_usages_ar')"
                          v-model="model.other_usages.ar"
                          :state="states('other_usages')"
                        ></b-form-textarea>
                      </b-form-group>
                      <b-form-group
                        :label="$t('validation.attributes.other_usages_en')"
                        label-class="font-weight-bold"
                        label-for="other_usages"
                        :invalid-feedback="feedback('other_usages.ar') || feedback('other_usages.en')" v-if="locale==='en'"
                      >
                        <b-form-textarea
                          id="other_usages_en" name="other_usages" :rows="3" :max-rows="100"
                          :placeholder="$t('validation.attributes.other_usages_en')"
                          v-model="model.other_usages.en"
                          :state="states('other_usages')"
                        ></b-form-textarea>
                      </b-form-group>
                      <b-form-group
                        :label="$t('validation.attributes.warnings_ar')"
                        label-class="font-weight-bold"
                        label-for="warnings"
                        :invalid-feedback="feedback('warnings.ar') || feedback('warnings.en')" v-if="locale==='ar'"
                      >
                        <b-form-textarea
                          id="warnings_ar" name="warnings" :rows="3" :max-rows="100"
                          :placeholder="$t('validation.attributes.warnings_ar')"
                          v-model="model.warnings.ar"
                          :state="states('warnings')"
                        ></b-form-textarea>
                      </b-form-group>
                      <b-form-group
                        :label="$t('validation.attributes.warnings_en')"
                        label-class="font-weight-bold"
                        label-for="warnings"
                        :invalid-feedback="feedback('warnings.ar') || feedback('warnings.en')" v-if="locale==='en'"
                      >
                        <b-form-textarea
                          id="warnings_en" name="warnings" :rows="3" :max-rows="100"
                          :placeholder="$t('validation.attributes.warnings_en')"
                          v-model="model.warnings.en"
                          :state="states('warnings')"
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                    <b-col xl="6">
                      <photo v-model="model.cover_image" :label="$t('validation.attributes.image')" field_name="cover_image" :image_path="model.cover_image_path" @image_deleted="model.cover_image_path = null" :validation="validation"></photo>

                      <photo v-for="(item, index) in model.images" :key="index" v-model="item.image" :label="$t('validation.attributes.cover_image') + ' ' + (index + 1)" :field_name="'images.' + index + '.image'" :image_path="item.image_path" @image_deleted="item.image_path = null" :deletable="true" :validation="validation"></photo>

                      <fieldset class="form-fieldset">
                        <h5 class="mb-5">
                          {{ $t('labels.backend.herbs.titles.images_gallery') }}
                        </h5>
                        <b-form-group
                          :invalid-feedback="feedback('images_gallery')"
                          :state="state('images_gallery')"
                        >
                        </b-form-group>

                        <template v-for="(image, index) in model.gallery_images">
                          <GalleryImage
                            v-model="model.gallery_images[index]"
                            :index="index"
                            :ref="'image-' + index"
                            :key="index"
                            v-if="image.id === 0 || image.url !== null"
                            @deleted="
                              dropFile(
                                'gallery_image-' + index,
                                image,
                                'gallery_images',
                                index
                              )
                            "
                            comp-name="gallery_image"
                            :validation="validation"
                          ></GalleryImage>
                        </template>

                        <div
                          class="float-right"
                        >
                          <b-button
                            size="sm"
                            variant="primary"
                            v-b-tooltip.hover
                            :title="$t('buttons.add_image')"
                            @click="addImage()"
                          >
                            <i class="fe fe-plus-circle"></i>
                          </b-button>
                        </div>
                      </fieldset>

                    </b-col>
                  </b-row>

                  <b-row slot="footer">
                    <b-col md>
                      <b-button to="/herbs" variant="danger" size="sm">
                        {{ $t('buttons.back') }}
                      </b-button>
                    </b-col>
                    <b-col md>
                      <b-button type="submit" variant="success" size="sm" class="float-right"
                                :disabled="pending"
                                v-if="(isNew && this.$app.user.can('create herbs')) || this.$app.user.can('edit herbs')">
                        {{ isNew ? $t('buttons.create') : $t('buttons.edit') }}
                      </b-button>
                    </b-col>
                  </b-row>
                  <atom-spinner
                    :animation-duration="1000"
                    :size="400"
                    :color="'#d3e335'"
                    :loading="loading"
                    :full="true"
                  ></atom-spinner>
                </b-card>
              </b-col>
            </b-row>
          </form>
        </b-tab>
        <b-tab
          :title="$t('labels.comments')"
          class="p-0 herbs-comments-tab"
          v-if="$app.user.can('view comments')"
        >
          <CommentList :for-include="true" :target-id="id" target-type="Herb"></CommentList>
        </b-tab>
      </b-tabs>
    </b-card>

  <!--    <comments-->
  <!--      ref="comments"-->
  <!--      model-name="Herb"-->
  <!--      :model-id="id"-->
  <!--      v-if="!isNew"-->
  <!--    ></comments>-->
  </div>
</template>

<script>
import form from '../mixins/form'
import Photo from './components/Photo'
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import GalleryImage from './components/GalleryImage'
// import Comments from './components/Comments'
import CommentList from './CommentList'

export default {
  name: 'HerbForm',
  components: { CommentList, GalleryImage, Multiselect, Photo },
  mixins: [form],
  data () {
    return {
      modelName: 'herb',
      resourceRoute: 'herbs',
      listPath: '/herbs',
      locale: 'en',
      categories: [],
      tags: [],
      links: [],
      isLoading: false,
      model: {
        name: { ar: null, en: null },
        other_names: { ar: null, en: null },
        used_parts: { ar: null, en: null },
        description: { ar: null, en: null },
        chemical_composition: { ar: null, en: null },
        medical_usages: { ar: null, en: null },
        other_usages: { ar: null, en: null },
        warnings: { ar: null, en: null },
        category: null,
        tags: [],
        links: [],
        cover_image: null,
        cover_image_path: null,
        nutritional_value_image: null,
        nutritional_value_image_path: null,
        images: [
          {
            id: 0,
            field_name: 'image_1',
            image: null,
            image_path: null
          },
          {
            id: 0,
            field_name: 'image_2',
            image: null,
            image_path: null
          },
          {
            id: 0,
            field_name: 'image_3',
            image: null,
            image_path: null
          }
        ],
        gallery_images: []
      }
    }
  },
  async created () {
    var { data } = await axios.get(this.$app.route(`categories.search`))
    this.categories = data.data.map((item) => {
      return { id: item.id, category_name: item.category_name }
    })
    //
    // ({data} = await axios.get(this.$app.route(`tags.search`)))
    // this.tags = data.data.map((item) => {
    //   return {id: item.id, tag_name: item.tag_name}
    // })
  },
  methods: {
    async asyncLinkHerb (query) {
      if (query.length <= 1) {
        this.links = []
        return
      }
      this.isLoading = true
      let params = {
        page: 1,
        perPage: 30
      }

      params.search = query

      let { data } = await axios.get(this.$app.route(`links.search`), {
        params: params
      })

      let d = data.data
      this.links = d.map((item) => ({ id: item.id, title: item.link_title }))

      this.isLoading = false
    },
    async asyncTagHerb (query) {
      if (query.length <= 1) {
        this.tags = []
        return
      }
      this.isLoading = true
      let params = {
        page: 1,
        perPage: 30
      }

      params.search = query

      let { data } = await axios.get(this.$app.route(`tags.search`), {
        params: params
      })

      let d = data.data
      this.tags = d.map((item) => ({ id: item.id, tag_name: item.tag_name }))

      this.isLoading = false
    },
    addImage () {
      this.model.gallery_images.push({
        id: 0,
        ar_title: null,
        en_title: null,
        link: null,
        copyright: null,
        url: null,
        file: null
      })
    },
    dropFile (ref, file, collection = 'attachments', index = 0) {
      if (this.$refs['image-' + index][0].$refs[ref] !== undefined) { this.$refs['image-' + index][0].$refs[ref].reset() }
      // if (file.id === 0 && file.url === null && file.file === null) {
      // console.log(file.id === 0 && file.url === null)
      if (file.id === 0 && file.url === null) {
      //   console.log(this.model[collection])
        this.model[collection].splice(index, 1)
      }
      file.url = null
    }
  }
}
</script>

<style>
.herbs-comments-tab .card {
    margin-bottom: 0;
}
</style>
