<template>
  <div>
    <b-row>
      <b-col xl="12">
        <b-row>
          <b-col lg="4" v-if="$app.user.can('view categories')">
            <router-link to="categories">
              <b-card bg-variant="primary" text-variant="white">
                <div class="d-flex align-items-center">
                  <span class="stamp stamp-md mr-3">
                    <font-awesome-icon icon="list" class="float-right" size="2x"></font-awesome-icon>
                  </span>
                  <div>
                    <h4 class="m-0">{{ this.$store.state.counters.categoriesCount }} <small>Categories</small></h4>
                  </div>
                </div>
              </b-card>
            </router-link>
          </b-col>
          <b-col lg="4" v-if="$app.user.can('view tags')">
            <router-link to="tags">
              <b-card bg-variant="success" text-variant="white">
                <div class="d-flex align-items-center">
                  <span class="stamp stamp-md mr-3">
                    <font-awesome-icon icon="tag" class="float-right" size="2x"></font-awesome-icon>
                  </span>
                  <div>
                    <h4 class="m-0">{{ this.$store.state.counters.tagsCount }} <small>Tags</small></h4>
                  </div>
                </div>
              </b-card>
            </router-link>
          </b-col>
          <b-col lg="4" v-if="$app.user.can('view advertisements')">
            <router-link to="advertisements">
              <b-card bg-variant="red" text-variant="white">
                <div class="d-flex align-items-center">
                  <span class="stamp stamp-md mr-3">
                    <font-awesome-icon icon="ad" class="float-right" size="2x"></font-awesome-icon>
                  </span>
                  <div>
                    <h4 class="m-0">{{ this.$store.state.counters.advertisementsCount }} <small>Advertisements</small></h4>
                  </div>
                </div>
              </b-card>
            </router-link>
          </b-col>
          <b-col lg="4" v-if="$app.user.can('view herbs')">
            <router-link to="herbs">
              <b-card bg-variant="blue" text-variant="white">
                <div class="d-flex align-items-center">
                  <span class="stamp stamp-md mr-3">
                    <font-awesome-icon icon="leaf" class="float-right" size="2x"></font-awesome-icon>
                  </span>
                  <div>
                    <h4 class="m-0">{{ this.$store.state.counters.herbsCount }} <small>Herbs</small></h4>
                  </div>
                </div>
              </b-card>
            </router-link>
          </b-col>
          <b-col lg="4" v-if="$app.user.can('view links')">
            <router-link to="links">
              <b-card bg-variant="yellow" text-variant="white">
                <div class="d-flex align-items-center">
                  <span class="stamp stamp-md mr-3">
                    <font-awesome-icon icon="link" class="float-right" size="2x"></font-awesome-icon>
                  </span>
                  <div>
                    <h4 class="m-0">{{ this.$store.state.counters.linksCount }} <small>Links</small></h4>
                  </div>
                </div>
              </b-card>
            </router-link>
          </b-col>
          <b-col lg="4" v-if="$app.user.can('view medical cases')">
            <router-link to="medical_cases">
              <b-card bg-variant="gray" text-variant="white">
                <div class="d-flex align-items-center">
                  <span class="stamp stamp-md mr-3">
                    <font-awesome-icon icon="user-md" class="float-right" size="2x"></font-awesome-icon>
                  </span>
                  <div>
                    <h4 class="m-0">{{ this.$store.state.counters.medicalCasesCount }} <small>Medical Cases</small></h4>
                  </div>
                </div>
              </b-card>
            </router-link>
          </b-col>
          <b-col lg="4" v-if="$app.user.can('view medical treatments')">
            <router-link to="medical_treatments">
              <b-card bg-variant="pink" text-variant="white">
                <div class="d-flex align-items-center">
                  <span class="stamp stamp-md mr-3">
                    <font-awesome-icon icon="mortar-pestle" class="float-right" size="2x"></font-awesome-icon>
                  </span>
                  <div>
                    <h4 class="m-0">{{ this.$store.state.counters.medicalTreatmentsCount }} <small>Medical Treatments</small></h4>
                  </div>
                </div>
              </b-card>
            </router-link>
          </b-col>
          <b-col lg="4" v-if="$app.user.can('view sliders')">
            <router-link to="sliders">
              <b-card bg-variant="purple" text-variant="white">
                <div class="d-flex align-items-center">
                  <span class="stamp stamp-md mr-3">
                    <font-awesome-icon icon="tv" class="float-right" size="2x"></font-awesome-icon>
                  </span>
                  <div>
                    <h4 class="m-0">{{ this.$store.state.counters.slidersCount }} <small>Sliders</small></h4>
                  </div>
                </div>
              </b-card>
            </router-link>
          </b-col>
          <b-col lg="4" v-if="$app.user.can('view clients')">
            <router-link to="clients">
              <b-card bg-variant="orange" text-variant="white">
                <div class="d-flex align-items-center">
                  <span class="stamp stamp-md mr-3">
                    <font-awesome-icon icon="tag" class="float-right" size="2x"></font-awesome-icon>
                  </span>
                  <div>
                    <h4 class="m-0">{{ this.$store.state.counters.clientsCount }} <small>Clients</small></h4>
                  </div>
                </div>
              </b-card>
            </router-link>
          </b-col>
          <b-col lg="4" v-if="$app.user.can('view users')">
            <router-link to="users">
              <b-card bg-variant="info" text-variant="white">
                <div class="d-flex align-items-center">
                  <span class="stamp stamp-md mr-3">
                    <font-awesome-icon icon="users" class="float-right" size="2x"></font-awesome-icon>
                  </span>
                  <div>
                    <h4 class="m-0">{{ this.$store.state.counters.usersCount }} <small>Users</small></h4>
                  </div>
                </div>
              </b-card>
            </router-link>
          </b-col>
          <b-col lg="4" v-if="$app.user.can('view comments')">
            <router-link to="comments">
              <b-card bg-variant="secondary" text-variant="white">
                <div class="d-flex align-items-center">
                  <span class="stamp stamp-md mr-3">
                    <font-awesome-icon icon="comment-dots" class="float-right" size="2x"></font-awesome-icon>
                  </span>
                  <div>
                    <h4 class="m-0">
                      {{ this.$store.state.counters.commentsCount }}
                      (<b-badge variant="primary">{{ this.$store.state.counters.approvedCommentsCount }}</b-badge>
                      <b-badge variant="danger">{{ this.$store.state.counters.rejectedCommentsCount }}</b-badge>
                      <b-badge variant="warning">{{ this.$store.state.counters.pendingCommentsCount }}</b-badge>)
                    <small>Comments</small></h4>
                  </div>
                </div>
              </b-card>
            </router-link>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: 'Dashboard',
  data () {
    return {
    }
  },
  async created () {
  }
}
</script>

<style scoped>
a {
    text-decoration: none !important;
}
</style>
