<template>
  <div class="animated fadeIn">
    <b-card>
      <template slot="header">
        <h3 class="card-title">{{ $t('labels.backend.comments.titles.index') }}</h3>
        <div class="card-options" v-if="$app.user.can('create comments')">
          <p-datetimepicker
            class="mr-2"
            :config="config"
            id="date"
            name="date"
            placeholder="Commented at between"
            v-model="extraSearchCriteria.date"
          ></p-datetimepicker>
          <b-form-checkbox-group
            class="mr-2"
            buttons button-variant="outline-primary"
            @input="onContextChangedWithPage"
            v-model="extraSearchCriteria.statuses"
            size="sm"
          >
            <b-form-checkbox
              class="cursor-pointer"
              v-for="(status, index) in statuses"
              :value="status" :key="index"
              button-variant="outline-primary"
            >{{ status.toUpperCase() }}</b-form-checkbox>
          </b-form-checkbox-group>
        </div>
      </template>
      <b-datatable ref="datasource"
                   @context-changed="onContextChanged"
                   search-route="comments.search"
                   delete-route="comments.destroy"
                   :extra-search-criteria="extraSearchCriteria"
                   :length-change="true" :paging="true" :infos="true" :export-data="false">
        <b-table ref="datatable"
                 striped
                 bordered
                 show-empty
                 stacked="md"
                 no-local-sorting
                 :empty-text="$t('labels.datatables.no_results')"
                 :empty-filtered-text="$t('labels.datatables.no_matched_results')"
                 :fields="fields"
                 :items="dataLoadProvider"
                 sort-by="id"
                 :sort-desc="true"
                 :busy.sync="isBusy"
        >
          <template slot="name" slot-scope="row">
            <router-link v-if="$app.user.can('edit clients') && row.item.client_id" :to="`/clients/${row.item.client_id}/edit`" v-text="row.value"></router-link>
            <span v-else v-text="row.value"></span>
          </template>
          <template slot="commented" slot-scope="row">
            <template>
              <font-awesome-icon :icon="target_types[row.item.commented_type].icon" style="color: #4CAF50"></font-awesome-icon>
              <router-link :to="`/${target_types[row.item.commented_type].link}/${row.item.commented.id}/edit`" v-text="row.item.commented.name">
              </router-link>
            </template>
          </template>
          <template slot="actions" slot-scope="row">
            <b-button v-if="row.item.can_approve && row.item.status !== 'approved'" size="sm" variant="primary" @click="onApprove(row.item.id)" v-b-tooltip.hover :title="$t('buttons.comments.approve')" class="mr-1">
              <font-awesome-icon icon="check-circle"></font-awesome-icon>
            </b-button>
            <b-button v-if="row.item.can_approve && row.item.status !== 'rejected'" size="sm" variant="warning" @click="onReject(row.item.id)" v-b-tooltip.hover :title="$t('buttons.comments.reject')" class="mr-1">
              <font-awesome-icon icon="times-circle"></font-awesome-icon>
            </b-button>
          </template>
        </b-table>
      </b-datatable>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'CommentList',
  props: {
    forInclude: {
      type: Boolean,
      default: false
    },
    targetId: {
      type: [Number, String],
      default: 0
    },
    targetType: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      locale: 'en',
      isBusy: false,
      extraSearchCriteria: {
        statuses: ['pending', 'approved', 'rejected'],
        date: null,
        targetId: null,
        targetType: null
      },
      statuses: ['pending', 'approved', 'rejected'],
      config: {
        wrap: true,
        allowInput: true,
        mode: 'range'
      },
      target_types: {
        Herb: { icon: 'leaf', link: 'herbs' },
        Category: { icon: 'list', link: 'categories' },
        MedicalCase: { icon: 'user-md', link: 'medical_cases' }
      }
    }
  },
  computed: {
    fields () {
      let fields = [
        { key: 'id', label: this.$t('validation.attributes.id'), class: 'text-center', sortable: true },
        { key: 'name', label: this.$t('validation.attributes.commenter'), class: 'text-center' },
        { key: 'comment', label: this.$t('validation.attributes.comment'), class: 'text-center', sortable: true }
      ]
      if (!this.forInclude) {
        fields.push({ key: 'commented', label: this.$t('validation.attributes.target'), class: 'text-center', sortable: false })
      }
      fields.push({ key: 'created_at', label: this.$t('validation.attributes.commented_at'), class: 'text-center', sortable: true })
      if (this.$app.user.can('approve comments') || this.$app.user.can('reject comments')) {
        fields.push({ key: 'actions', label: this.$t('labels.actions'), 'class': 'nowrap text-center' })
      }
      return fields
    }
  },
  watch: {
    'extraSearchCriteria.date': function (newVal) {
      if (newVal.match(/^\d{4}-\d{1,2}-\d{1,2} to \d{4}-\d{1,2}-\d{1,2}$/g)) {
        this.onContextChangedWithPage()
      }
    }
  },
  created () {
    if (this.targetId !== 0 && this.targetType !== null) {
      this.extraSearchCriteria.targetId = this.targetId
      this.extraSearchCriteria.targetType = this.targetType
    }
  },
  methods: {
    dataLoadProvider (ctx) {
      return this.$refs.datasource.loadData(ctx.sortBy, ctx.sortDesc)
    },
    onContextChanged () {
      return this.$refs.datatable.refresh()
    },
    onContextChangedWithPage () {
      this.$refs.datasource.currentPage = 1
      this.onContextChanged()
    },
    onApprove (id) {
      axios.post(this.$app.route('comments.approve', { comment: id }))
        .then((response) => {
          this.$app.noty[response.data.status](response.data.message)
          this.onContextChanged()
        })
        .catch((error) => {
          this.$app.error(error)
        })
    },
    onReject (id) {
      axios.post(this.$app.route('comments.reject', { comment: id }))
        .then((response) => {
          this.$app.noty[response.data.status](response.data.message)
          this.onContextChanged()
        })
        .catch((error) => {
          this.$app.error(error)
        })
    }
  }
}
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}
</style>
