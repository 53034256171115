import Vue from 'vue'
import Router from 'vue-router'

// Containers
import Full from '../containers/Full'

// Views
import Search from '../views/Search'
import Test from '../views/Test'
import Dashboard from '../views/Dashboard'
import UserProfile from '../views/UserProfile'
import UserForm from '../views/UserForm'
import UserList from '../views/UserList'
import ClientForm from '../views/ClientForm'
import ClientList from '../views/ClientList'
import RoleForm from '../views/RoleForm'
import RoleList from '../views/RoleList'
import TagForm from '../views/TagForm'
import TagList from '../views/TagList'
import CategoryForm from '../views/CategoryForm'
import CategoryList from '../views/CategoryList'
import LinkForm from '../views/LinkForm'
import LinkList from '../views/LinkList'
import CommentList from '../views/CommentList'
import HerbForm from '../views/HerbForm'
import HerbList from '../views/HerbList'
import MedicalCaseForm from '../views/MedicalCaseForm'
import MedicalCaseList from '../views/MedicalCaseList'
import SliderForm from '../views/SliderForm'
import SliderList from '../views/SliderList'
import AdvertisementForm from '../views/AdvertisementForm'
import AdvertisementList from '../views/AdvertisementList'
import MedicalTreatmentForm from '../views/MedicalTreatmentForm'
import MedicalTreatmentList from '../views/MedicalTreatmentList'
import Test1 from '../views/Test1'

Vue.use(Router)

export function createRouter (base, i18n, route) {
  const router = new Router({
    mode: 'history',
    base: base,
    linkActiveClass: 'open active',
    scrollBehavior: () => ({ y: 0 }),
    routes: [
      {
        path: '/',
        redirect: '/dashboard',
        name: 'home',
        component: Full,
        meta: {
          label: i18n.t('labels.backend.titles.administration')
        },
        children: [
          {
            path: 'search',
            name: 'search',
            component: Search,
            meta: {
              label: i18n.t('labels.search')
            }
          },
          {
            path: 'dashboard',
            name: 'dashboard',
            component: Dashboard,
            meta: {
              label: i18n.t('labels.backend.titles.dashboard')
            }
          },
          {
            path: 'user_profile',
            name: 'user_profile',
            component: UserProfile,
            meta: {
              label: i18n.t('labels.backend.users.titles.profile')
            }
          },
          {
            path: 'users',
            component: {
              render (c) {
                return c('router-view')
              }
            },
            meta: {
              label: i18n.t('labels.backend.users.titles.main')
            },
            children: [
              {
                path: '/',
                name: 'users',
                component: UserList,
                meta: {
                  label: i18n.t('labels.backend.users.titles.index'),
                  permission: 'view users'
                }
              },
              {
                path: 'create',
                name: 'users_create',
                component: UserForm,
                meta: {
                  label: i18n.t('labels.backend.users.titles.create'),
                  permission: 'create users'
                }
              },
              {
                path: ':id/edit',
                name: 'users_edit',
                component: UserForm,
                props: true,
                meta: {
                  label: i18n.t('labels.backend.users.titles.edit'),
                  permission: 'edit users'
                }
              }
            ]
          },
          {
            path: 'clients',
            component: {
              render (c) {
                return c('router-view')
              }
            },
            meta: {
              label: i18n.t('labels.backend.clients.titles.main')
            },
            children: [
              {
                path: '/',
                name: 'clients',
                component: ClientList,
                meta: {
                  label: i18n.t('labels.backend.clients.titles.index'),
                  permission: 'view users'
                }
              },
              {
                path: ':id/edit',
                name: 'clients_edit',
                component: ClientForm,
                props: true,
                meta: {
                  label: i18n.t('labels.backend.clients.titles.edit'),
                  permission: 'edit clients'
                }
              }
            ]
          },
          {
            path: 'roles',
            component: {
              render (c) {
                return c('router-view')
              }
            },
            meta: {
              label: i18n.t('labels.backend.roles.titles.main')
            },
            children: [
              {
                path: '/',
                name: 'roles',
                component: RoleList,
                meta: {
                  label: i18n.t('labels.backend.roles.titles.index'),
                  permission: 'view roles'
                }
              },
              {
                path: 'create',
                name: 'roles_create',
                component: RoleForm,
                meta: {
                  label: i18n.t('labels.backend.roles.titles.create'),
                  permission: 'create roles'
                }
              },
              {
                path: ':id/edit',
                name: 'roles_edit',
                component: RoleForm,
                props: true,
                meta: {
                  label: i18n.t('labels.backend.roles.titles.edit'),
                  permission: 'edit roles'
                }
              }
            ]
          },
          {
            path: 'tags',
            component: {
              render (c) {
                return c('router-view')
              }
            },
            meta: {
              label: i18n.t('labels.backend.tags.titles.main')
            },
            children: [
              {
                path: '/',
                name: 'tags',
                component: TagList,
                meta: {
                  label: i18n.t('labels.backend.tags.titles.index'),
                  permission: 'view tags'
                }
              },
              {
                path: 'create',
                name: 'tags_create',
                component: TagForm,
                meta: {
                  label: i18n.t('labels.backend.tags.titles.create'),
                  permission: 'create tags'
                }
              },
              {
                path: ':id/edit',
                name: 'tags_edit',
                component: TagForm,
                props: true,
                meta: {
                  label: i18n.t('labels.backend.tags.titles.edit'),
                  permission: 'edit tags'
                }
              }
            ]
          },
          {
            path: 'categories',
            component: {
              render (c) {
                return c('router-view')
              }
            },
            meta: {
              label: i18n.t('labels.backend.categories.titles.main')
            },
            children: [
              {
                path: '/',
                name: 'categories',
                component: CategoryList,
                meta: {
                  label: i18n.t('labels.backend.categories.titles.index'),
                  permission: 'view categories'
                }
              },
              {
                path: 'create',
                name: 'categories_create',
                component: CategoryForm,
                meta: {
                  label: i18n.t('labels.backend.categories.titles.create'),
                  permission: 'create categories'
                }
              },
              {
                path: ':id/edit',
                name: 'categories_edit',
                component: CategoryForm,
                props: true,
                meta: {
                  label: i18n.t('labels.backend.categories.titles.edit'),
                  permission: 'edit categories'
                }
              }
            ]
          },
          {
            path: 'links',
            component: {
              render (c) {
                return c('router-view')
              }
            },
            meta: {
              label: i18n.t('labels.backend.links.titles.main')
            },
            children: [
              {
                path: '/',
                name: 'links',
                component: LinkList,
                meta: {
                  label: i18n.t('labels.backend.links.titles.index'),
                  permission: 'view links'
                }
              },
              {
                path: 'create',
                name: 'links_create',
                component: LinkForm,
                meta: {
                  label: i18n.t('labels.backend.links.titles.create'),
                  permission: 'create links'
                }
              },
              {
                path: ':id/edit',
                name: 'links_edit',
                component: LinkForm,
                props: true,
                meta: {
                  label: i18n.t('labels.backend.links.titles.edit'),
                  permission: 'edit links'
                }
              }
            ]
          },
          {
            path: 'comments',
            component: {
              render (c) {
                return c('router-view')
              }
            },
            meta: {
              label: i18n.t('labels.backend.comments.titles.main')
            },
            children: [
              {
                path: '/',
                name: 'comments',
                component: CommentList,
                meta: {
                  label: i18n.t('labels.backend.comments.titles.index'),
                  permission: 'view comments'
                }
              }
            ]
          },
          {
            path: 'herbs',
            component: {
              render (c) {
                return c('router-view')
              }
            },
            meta: {
              label: i18n.t('labels.backend.herbs.titles.main')
            },
            children: [
              {
                path: '/',
                name: 'herbs',
                component: HerbList,
                meta: {
                  label: i18n.t('labels.backend.herbs.titles.index'),
                  permission: 'view herbs'
                }
              },
              {
                path: 'create',
                name: 'herbs_create',
                component: HerbForm,
                meta: {
                  label: i18n.t('labels.backend.herbs.titles.create'),
                  permission: 'create herbs'
                }
              },
              {
                path: ':id/edit',
                name: 'herbs_edit',
                component: HerbForm,
                props: true,
                meta: {
                  label: i18n.t('labels.backend.herbs.titles.edit'),
                  permission: 'edit herbs'
                }
              }
            ]
          },
          {
            path: 'medical_cases',
            component: {
              render (c) {
                return c('router-view')
              }
            },
            meta: {
              label: i18n.t('labels.backend.medical_cases.titles.main')
            },
            children: [
              {
                path: '/',
                name: 'medicalcases',
                component: MedicalCaseList,
                meta: {
                  label: i18n.t('labels.backend.medical_cases.titles.index'),
                  permission: 'view medical cases'
                }
              },
              {
                path: 'create',
                name: 'medicalcases_create',
                component: MedicalCaseForm,
                meta: {
                  label: i18n.t('labels.backend.medical_cases.titles.create'),
                  permission: 'create medical cases'
                }
              },
              {
                path: ':id/edit',
                name: 'medicalcases_edit',
                component: MedicalCaseForm,
                props: true,
                meta: {
                  label: i18n.t('labels.backend.medical_cases.titles.edit'),
                  permission: 'edit medical cases'
                }
              }
            ]
          },
          {
            path: 'sliders',
            component: {
              render (c) {
                return c('router-view')
              }
            },
            meta: {
              label: i18n.t('labels.backend.sliders.titles.main')
            },
            children: [
              {
                path: '/',
                name: 'sliders',
                component: SliderList,
                meta: {
                  label: i18n.t('labels.backend.sliders.titles.index'),
                  permission: 'view sliders'
                }
              },
              {
                path: 'create',
                name: 'sliders_create',
                component: SliderForm,
                meta: {
                  label: i18n.t('labels.backend.sliders.titles.create'),
                  permission: 'create sliders'
                }
              },
              {
                path: ':id/edit',
                name: 'sliders_edit',
                component: SliderForm,
                props: true,
                meta: {
                  label: i18n.t('labels.backend.sliders.titles.edit'),
                  permission: 'edit sliders'
                }
              }
            ]
          },
          {
            path: 'advertisements',
            component: {
              render (c) {
                return c('router-view')
              }
            },
            meta: {
              label: i18n.t('labels.backend.advertisements.titles.main')
            },
            children: [
              {
                path: '/',
                name: 'advertisements',
                component: AdvertisementList,
                meta: {
                  label: i18n.t('labels.backend.advertisements.titles.index'),
                  permission: 'view advertisements'
                }
              },
              {
                path: 'create',
                name: 'advertisements_create',
                component: AdvertisementForm,
                meta: {
                  label: i18n.t('labels.backend.advertisements.titles.create'),
                  permission: 'create advertisements'
                }
              },
              {
                path: ':id/edit',
                name: 'advertisements_edit',
                component: AdvertisementForm,
                props: true,
                meta: {
                  label: i18n.t('labels.backend.advertisements.titles.edit'),
                  permission: 'edit advertisements'
                }
              }
            ]
          },
          {
            path: 'medical_treatments',
            component: {
              render (c) {
                return c('router-view')
              }
            },
            meta: {
              label: i18n.t('labels.backend.medical_treatments.titles.main')
            },
            children: [
              {
                path: '/',
                name: 'medical_treatments',
                component: MedicalTreatmentList,
                meta: {
                  label: i18n.t('labels.backend.medical_treatments.titles.index'),
                  permission: 'view medical treatments'
                }
              },
              {
                path: 'create',
                name: 'medical_treatments_create',
                component: MedicalTreatmentForm,
                meta: {
                  label: i18n.t('labels.backend.medical_treatments.titles.create'),
                  permission: 'create medical treatments'
                }
              },
              {
                path: ':id/edit',
                name: 'medical_treatments_edit',
                component: MedicalTreatmentForm,
                props: true,
                meta: {
                  label: i18n.t('labels.backend.medical_treatments.titles.edit'),
                  permission: 'edit medical treatments'
                }
              }
            ]
          }
        ]
      },
      {
        path: '/frontend/herbs',
        name: 'frontend.herbs',
        component: Test,
        meta: {
          label: i18n.t('labels.frontend.herbs')
        }
      },
      {
        path: '/frontend/herbs/:id',
        props: true,
        name: 'frontend.herbs.details',
        component: Test1,
        meta: {
          label: i18n.t('labels.frontend.herb')
        }
      }
    ]
  })

  router.beforeEach(async (to, from, next) => {
    if (to.meta.hasOwnProperty('permission') &&
      (
        (typeof to.meta.permission === 'string' && !router.app.$app.user.can(to.meta.permission)) ||
        (typeof to.meta.permission === 'function' && !to.meta.permission(router.app.$app))
      )
    ) {
      next('/')
    }

    next()
  })
  return router
}
