<template>
  <div class="animated fadeIn">
    <form @submit.prevent="onSubmit">
      <b-row class="justify-content-center">
        <b-col xl="12">
          <b-card>
            <template slot="header">
              <h3 class="card-title">{{ isNew ? $t('labels.backend.medical_treatments.titles.create') : $t('labels.backend.medical_treatments.titles.edit') }}</h3>
              <div class="card-options">
                <b-form-radio-group
                  buttons button-variant="outline-secondary" v-model="locale" :options="['ar','en']" name="locale"
                ></b-form-radio-group>
              </div>
            </template>
            <b-row class="justify-content-center">
              <b-col xl="6">
                <b-form-group
                  :label="$t('validation.attributes.name_ar')"
                  label-for="name"
                  :label-cols="4"
                  :invalid-feedback="feedback('name.ar') || feedback('name.en')" v-if="locale==='ar'"
                >
                  <b-form-input
                    id="name_ar"
                    name="name_ar"
                    :placeholder="$t('validation.attributes.name_ar')"
                    v-model="model.name.ar"
                    :state="states('name')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  :label="$t('validation.attributes.name_en')"
                  label-for="name"
                  :label-cols="4"
                  :invalid-feedback="feedback('name.ar') || feedback('name.en')" v-if="locale==='en'"
                >
                  <b-form-input
                    id="name_en"
                    name="name_en"
                    :placeholder="$t('validation.attributes.name_en')"
                    v-model="model.name.en"
                    :state="states('name')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  :label="$t('validation.attributes.description_ar')"
                  label-for="description_ar"
                  :invalid-feedback="feedback('description.ar') || feedback('description.en')" v-if="locale==='ar'"
                  :state="states('description')"
                >
                  <rich-text-editor
                    key="description_ar"
                    name="description_ar"
                    v-model="model.description.ar"
                  ></rich-text-editor>
                </b-form-group>
                <b-form-group
                  :label="$t('validation.attributes.description_en')"
                  label-for="description_en"
                  :invalid-feedback="feedback('description.ar') || feedback('description.en')" v-if="locale==='en'"
                  :state="states('description')"
                >
                  <label slot="label" label-for="description_en" style="width: 100%">
                    {{ $t('validation.attributes.description_en') }}
                  </label>
                  <rich-text-editor
                    key="description_en"
                    name="description_en"
                    v-model="model.description.en"
                  ></rich-text-editor>
                </b-form-group>
                <div>
                  <b-btn v-b-modal="'descriptionModal'" size="sm" style="float: left"><font-awesome-icon icon="eye" variant="default"></font-awesome-icon></b-btn>
                  <b-modal id="descriptionModal" size="lg" :title="locale==='en' ? $t('validation.attributes.description_en') : $t('validation.attributes.description_ar')">
                    <div v-html="locale==='en' ? model.description.en : model.description.ar" class="ck-content"></div>
                  </b-modal>
                </div><br><br>
                <b-form-group
                  :label="$t('validation.attributes.other_info_ar')"
                  label-for="other_info"
                  :invalid-feedback="feedback('other_info.ar') || feedback('other_info.en')" v-if="locale==='ar'"
                >
                  <rich-text-editor
                    key="other_info_ar"
                    name="other_info_ar"
                    v-model="model.other_info.ar"
                  ></rich-text-editor>
                </b-form-group>
                <b-form-group
                  :label="$t('validation.attributes.other_info_en')"
                  label-for="other_info"
                  :invalid-feedback="feedback('other_info.ar') || feedback('other_info.en')" v-if="locale==='en'"
                >
                  <rich-text-editor
                    key="other_info_en"
                    name="other_info_en"
                    v-model="model.other_info.en"
                  ></rich-text-editor>
                </b-form-group>
                <div>
                  <b-btn v-b-modal="'otherInfoModal'" size="sm" style="float: left"><font-awesome-icon icon="eye" variant="default"></font-awesome-icon></b-btn>
                  <b-modal id="otherInfoModal" size="lg" :title="locale==='en' ? $t('validation.attributes.other_info_en') : $t('validation.attributes.other_info_ar')">
                    <div v-html="locale==='en' ? model.other_info.en : model.other_info.ar" class="ck-content"></div>
                  </b-modal>
                </div><br><br>
              </b-col>
              <b-col xl="6">
                <b-form-group
                  :label="$t('validation.attributes.link')"
                  label-for="links"
                  :label-cols="4"
                  :invalid-feedback="feedback('links')"
                  :state="state('links')"
                >
                  <multiselect v-model="model.links" :options="links" id="links" name="links"
                               track-by="id" label="title" :multiple="true"
                               :searchable="true" :close-on-select="false" :show-labels="false"
                               :placeholder="'-- ' + $t('validation.attributes.link') + ' --'"
                               :loading="isLoading" :internal-search="false" :clear-on-select="true"
                               :options-limit="300" :option-height="100"
                               :max-height="600" :show-no-results="true" @search-change="asyncLinkHerb"></multiselect>
                </b-form-group>
                <b-form-group
                  :label="$t('validation.attributes.herbs')"
                  label-for="herbs"
                  :label-cols="4"
                  :invalid-feedback="feedback('herbs')"
                  :state="state('herbs')"
                >
                  <multiselect v-model="model.herbs" :options="herbs" :multiple="true"
                               id="herbs" label="herb_name" track-by="id"
                               :placeholder="'-- ' + $t('validation.attributes.herbs') + ' --'"
                               :searchable="true" :show-labels="false"
                               :loading="isLoading" :internal-search="false" :clear-on-select="true"
                               :close-on-select="false" :options-limit="300"
                               :option-height="100"
                               :max-height="600" :show-no-results="false" @search-change="asyncFindHerb">
                    <template slot="singleLabel" slot-scope="props"><img class="option__image" :src="props.option.img"><span class="option__desc"><span class="option__title">{{ props.option.name_ar }}<br>{{ props.option.name_en }}</span></span></template>
                    <template slot="option" slot-scope="props">
                      <img class="option__image" :src="props.option.img" style="width: 40%">
                      <div class="option__desc">
                        <span class="option__title">{{ props.option.name_ar }}<br>{{ props.option.name_en }}</span>
                      </div>
                    </template>
                  </multiselect>
                </b-form-group>
                <photo v-model="model.image" :label="$t('validation.attributes.image')" field_name="image" :image_path="model.image_path"></photo>
              </b-col>
            </b-row>

            <b-row slot="footer">
              <b-col md>
                <b-button to="/medical_treatments" variant="danger" size="sm">
                  {{ $t('buttons.back') }}
                </b-button>
              </b-col>
              <b-col md>
                <b-button type="submit" variant="success" size="sm" class="float-right"
                          :disabled="pending"
                          v-if="(isNew && this.$app.user.can('create medical treatments')) || this.$app.user.can('edit medical treatments')">
                  {{ isNew ? $t('buttons.create') : $t('buttons.edit') }}
                </b-button>
              </b-col>
            </b-row>
            <atom-spinner
              :animation-duration="1000"
              :size="400"
              :color="'#d3e335'"
              :loading="loading"
              :full="true"
            ></atom-spinner>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import form from '../mixins/form'
import Photo from './components/Photo'
import Multiselect from 'vue-multiselect'
import axios from 'axios'
import RichTextEditor from '../components/Plugins/RichTextEditor'

export default {
  name: 'MedicalTreatmentForm',
  components: { Photo, Multiselect, RichTextEditor },
  mixins: [form],
  data () {
    return {
      modelName: 'medical_treatment',
      resourceRoute: 'medical_treatments',
      listPath: '/medical_treatments',
      locale: 'en',
      herbs: [],
      isLoading: false,
      links: [],
      model: {
        name: { ar: null, en: null },
        // description: {ar: '<p></p>', en: '<p></p>'},
        description: { ar: null, en: null },
        other_info: { ar: null, en: null },
        image: null,
        image_path: null,
        herbs: [],
        links: []
      }
    }
  },
  methods: {
    async asyncLinkHerb (query) {
      if (query.length <= 1) {
        this.links = []
        return
      }
      this.isLoading = true
      let params = {
        page: 1,
        perPage: 30
      }

      params.search = query

      let { data } = await axios.get(this.$app.route(`links.search`), {
        params: params
      })

      let d = data.data
      this.links = d.map((item) => ({ id: item.id, title: item.link_title }))

      this.isLoading = false
    },
    async asyncFindHerb (query) {
      if (query.length <= 1) {
        this.herbs = []
        return
      }
      this.isLoading = true
      let params = {
        page: 1,
        perPage: 30
      }

      params.search = query

      let { data } = await axios.get(this.$app.route(`herbs.search`), {
        params: params
      })

      let d = data.data
      this.herbs = d.map((item) => {
        return {
          id: item.id,
          name: item.name.ar + ' (' + item.name.en + ')',
          herb_name: item.name.ar + ' (' + item.name.en + ')',
          name_ar: item.name.ar,
          name_en: item.name.en,
          img: item.thumbnail_cover_image_path
        }
      })

      this.isLoading = false
    },
    transform () {
      if (this.model.description.ar === '<p></p>' || this.model.description.ar === '<p>&nbsp;</p>') this.model.description.ar = null
      if (this.model.description.en === '<p></p>' || this.model.description.en === '<p>&nbsp;</p>') this.model.description.en = null
    }
  }
}
</script>

<style>
  .is-invalid {
    --ck-color-base-border: #cd201f;
  }
</style>
