<template>

  <div :ref="editorEl" v-html="value"></div>

</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null
    },
    minimum: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      editor: null
    }
  },
  computed: {
    editorEl: function () {
      return 'editorEl' + Math.random().toString(36).substring(7)
    }
  },
  watch: {
    value (newValue) {
      if (this.editor && newValue !== this.editor.getData() && newValue !== null) {
      // console.log(newValue, this.editor.getData())
      // if (this.editor && newValue !== this.editor.getData()) {
        this.editor.setData(newValue)
      }
    }
  },
  mounted () {
    this.createInstance()
  },
  beforeDestroy () {
    if (this.editor) {
      this.editor.destroy()
    }
  },
  methods: {
    async createInstance () {
      if (!this.editor) {
        try {
          this.editor = await window.ClassicEditor.create(this.$refs[this.editorEl], {
            toolbar: !this.minimum ? [
              'heading',
              'bold',
              'italic',
              'link',
              'bulletedList',
              'numberedList',
              'imageUpload',
              'blockQuote',
              'undo',
              'redo'
            ] : [
              'link'
            ],
            image: {
              toolbar: [
                'imageTextAlternative',
                '|',
                'imageStyle:alignLeft',
                'imageStyle:full',
                'imageStyle:alignRight'
              ],
              styles: ['full', 'alignLeft', 'alignRight']
            },
            ckfinder: {
              uploadUrl: this.$app.route('images.upload')
            },
            height: 500
          })
        } catch (e) {
          throw e
        }
        if (this.editor) {
          this.editor.model.document.on('change', () => {
            this.$emit('input', this.editor.getData())
          })
        }
      }
    }
  }
}
</script>

<style>
  .ck-editor__editable {
    min-height: 200px;
  }
</style>
<style lang="scss">
  :root {
    --ck-image-style-spacing: 1.5em;
  }

  .ck-content {
  & .image-style-side,
  & .image-style-align-left,
  & .image-style-align-center,
  & .image-style-align-right {
      max-width: 50%;
    }

  & .image-style-side {
      float: right;
      margin-left: var(--ck-image-style-spacing);
    }

  & .image-style-align-left {
      float: left;
      margin-right: var(--ck-image-style-spacing);
    }

  & .image-style-align-center {
      margin-left: auto;
      margin-right: auto;
    }

  & .image-style-align-right {
      float: right;
      margin-left: var(--ck-image-style-spacing);
    }
  }
</style>
