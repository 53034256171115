<template>
  <div class="animated fadeIn">
    <b-card>
      <template slot="header">
        <h3 class="card-title">{{ $t('labels.backend.links.titles.index') }}</h3>
        <div class="card-options" v-if="$app.user.can('create links')">
          <b-form-radio-group
            buttons button-variant="outline-secondary" v-model="locale" :options="['ar','en']" name="locale"
          ></b-form-radio-group>
          <b-button class="ml-2" to="/links/create" variant="primary" v-b-tooltip.hover :title="$t('buttons.links.create')" size="sm" v-if="$app.user.can('create links')">
            <i class="fe fe-plus-circle"></i>
          </b-button>
        </div>
      </template>
      <b-datatable ref="datasource"
                   @context-changed="onContextChanged"
                   search-route="links.search"
                   delete-route="links.destroy"
                   :length-change="true" :paging="true" :infos="true" :export-data="false">
        <b-table ref="datatable"
                 striped
                 bordered
                 show-empty
                 stacked="md"
                 no-local-sorting
                 :empty-text="$t('labels.datatables.no_results')"
                 :empty-filtered-text="$t('labels.datatables.no_matched_results')"
                 :fields="fields"
                 :items="dataLoadProvider"
                 sort-by="id"
                 :sort-desc="true"
                 :busy.sync="isBusy"
        >
          <template slot="id" slot-scope="row">
            <router-link v-if="row.item.can_edit" :to="`/links/${row.item.id}/edit`" v-text="row.value"></router-link>
            <span v-else v-text="row.value"></span>
          </template>
          <template slot="title" slot-scope="row">
            <span v-text="row.value[locale]"></span>
          </template>
          <template slot="link" slot-scope="row">
            <span style="word-break: break-all"><a :href="row.value">{{ row.value }}</a></span>
          </template>
          <template slot="actions" slot-scope="row">
            <b-button v-if="row.item.can_edit" size="sm" variant="primary" :to="`/links/${row.item.id}/edit`" v-b-tooltip.hover :title="$t('buttons.edit')" class="mr-1">
              <i class="fe fe-edit"></i>
            </b-button>
            <b-button v-if="row.item.can_delete" size="sm" variant="danger" v-b-tooltip.hover :title="$t('buttons.delete')" @click.stop="onDelete(row.item.id)">
              <i class="fe fe-trash"></i>
            </b-button>
          </template>
        </b-table>
      </b-datatable>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'LinkList',
  data () {
    return {
      locale: 'en',
      isBusy: false
    }
  },
  computed: {
    fields () {
      let fields = [
        { key: 'id', label: this.$t('validation.attributes.id'), class: 'text-center', sortable: true },
        { key: 'title', label: this.$t('validation.attributes.title'), class: 'text-center', sortable: true },
        { key: 'link', label: this.$t('validation.attributes.link'), sortable: true }
      ]
      if (this.$app.user.can('edit links') || this.$app.user.can('delete links')) {
        fields.push({ key: 'actions', label: this.$t('labels.actions'), 'class': 'nowrap text-center' })
      }
      return fields
    }
  },
  methods: {
    dataLoadProvider (ctx) {
      return this.$refs.datasource.loadData(ctx.sortBy, ctx.sortDesc)
    },
    onContextChanged () {
      return this.$refs.datatable.refresh()
    },
    onDelete (id) {
      this.$refs.datasource.deleteRow({ link: id })
    }
  }
}
</script>
