<template>
  <div class="animated fadeIn">
    <form @submit.prevent="onSubmit">
      <b-row class="justify-content-center">
        <b-col xl="12">
          <b-card>
            <template slot="header">
              <h3 class="card-title">{{ isNew ? $t('labels.backend.medical_cases.titles.create') : $t('labels.backend.medical_cases.titles.edit') }}</h3>
              <div class="card-options">
                <b-form-radio-group
                  buttons button-variant="outline-secondary" v-model="locale" :options="['ar','en']" name="locale"
                ></b-form-radio-group>
              </div>
            </template>
            <b-row class="justify-content-center">
              <b-col xl="6">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('validation.attributes.name_ar')"
                  label-for="name"
                  :label-cols="4"
                  :invalid-feedback="feedback('name.ar') || feedback('name.en')" v-if="locale==='ar'"
                >
                  <b-form-input
                    id="name_ar"
                    name="name_ar"
                    :placeholder="$t('validation.attributes.name_ar')"
                    v-model="model.name.ar"
                    :state="states('name')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('validation.attributes.name_en')"
                  label-for="name"
                  :label-cols="4"
                  :invalid-feedback="feedback('name.ar') || feedback('name.en')" v-if="locale==='en'"
                >
                  <b-form-input
                    id="name_en"
                    name="name_en"
                    :placeholder="$t('validation.attributes.name_en')"
                    v-model="model.name.en"
                    :state="states('name')"
                  ></b-form-input>
                </b-form-group>

                <photo :cols="4" v-model="model.image" :label="$t('validation.attributes.image')" field_name="image" :image_path="model.image_path"></photo>

              </b-col>
              <b-col xl="6">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('validation.attributes.description_ar')"
                  label-for="description"
                  :invalid-feedback="feedback('description.ar') || feedback('description.en')" v-if="locale==='ar'"
                >
                  <!--                  <b-form-textarea-->
                  <!--                    id="description" name="description" :rows="8"-->
                  <!--                    :placeholder="$t('validation.attributes.description_ar')"-->
                  <!--                    v-model="model.description.ar"-->
                  <!--                    :state="states('description')"-->
                  <!--                  ></b-form-textarea>-->
                  <rich-text-editor
                    key="description_ar"
                    name="description_ar"
                    v-model="model.description.ar"
                  ></rich-text-editor>
                </b-form-group>
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('validation.attributes.description_en')"
                  label-for="description"
                  :invalid-feedback="feedback('description.ar') || feedback('description.en')" v-if="locale==='en'"
                >
                  <!--                  <b-form-textarea-->
                  <!--                    id="description" name="description" :rows="8"-->
                  <!--                    :placeholder="$t('validation.attributes.description_en')"-->
                  <!--                    v-model="model.description.en"-->
                  <!--                    :state="states('description')"-->
                  <!--                  ></b-form-textarea>-->
                  <rich-text-editor
                    key="description_en"
                    name="description_en"
                    v-model="model.description.en"
                  ></rich-text-editor>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <fieldset>
                  <h5 class="mb-5">
                    {{ $t('validation.attributes.herbs') }}
                  </h5>
                  <b-form-group
                    :invalid-feedback="feedback('herbs')"
                    :state="state('herbs')"
                  >
                  </b-form-group>

                  <table class="table table-striped table-hover" v-if="model.herbs.length">
                    <thead class="thead-light">
                      <tr>
                        <th scope="col">{{ $t('validation.attributes.herb') }}</th>
                        <th scope="col">{{ $t('validation.attributes.description') }}</th>
                        <th scope="col">{{ $t('labels.actions') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(herb, index) in model.herbs">
                        <HerbSelectorAlt
                          v-model="model.herbs[index]"
                          :index="index"
                          :key="index"
                          v-if="herb.id !== null"
                          :locale="locale"
                          @deleted="
                            dropHerb(
                              index
                            )
                          "
                          comp-name="herbs_selector"
                        ></HerbSelectorAlt>
                      </template>
                    </tbody>
                  </table>

                  <!--                  <template v-for="(image, index) in model.herbs">-->
                  <!--                    <HerbSelector-->
                  <!--                      v-model="model.herbs[index]"-->
                  <!--                      :index="index"-->
                  <!--                      :key="index"-->
                  <!--                      v-if="image.id !== null"-->
                  <!--                      :locale="locale"-->
                  <!--                      @deleted="-->
                  <!--                        dropHerb(-->
                  <!--                          index-->
                  <!--                        )-->
                  <!--                      "-->
                  <!--                      comp-name="herbs_selector"-->
                  <!--                    ></HerbSelector>-->
                  <!--                  </template>-->

                  <div
                    class="float-right"
                  >
                    <b-button
                      size="sm"
                      variant="primary"
                      v-b-tooltip.hover
                      :title="$t('buttons.add_herb')"
                      @click="addHerb()"
                    >
                      <i class="fe fe-plus-circle"></i>
                    </b-button>
                  </div>
                </fieldset>

              </b-col>
            </b-row>

            <b-row slot="footer">
              <b-col md>
                <b-button to="/medical_cases" variant="danger" size="sm">
                  {{ $t('buttons.back') }}
                </b-button>
              </b-col>
              <b-col md>
                <b-button type="submit" variant="success" size="sm" class="float-right"
                          :disabled="pending"
                          v-if="(isNew && this.$app.user.can('create medical cases')) || this.$app.user.can('edit medical cases')">
                  {{ isNew ? $t('buttons.create') : $t('buttons.edit') }}
                </b-button>
              </b-col>
            </b-row>
            <atom-spinner
              :animation-duration="1000"
              :size="400"
              :color="'#d3e335'"
              :loading="loading"
              :full="true"
            ></atom-spinner>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import form from '../mixins/form'
import Photo from './components/Photo'
import HerbSelector from './components/HerbSelector'
import Multiselect from 'vue-multiselect'
import HerbSelectorAlt from './components/HerbSelectorAlt'
import RichTextEditor from '../components/Plugins/RichTextEditor'

export default {
  name: 'MedicalCaseForm',
  components: { HerbSelectorAlt, Photo, Multiselect, HerbSelector, RichTextEditor },
  mixins: [form],
  data () {
    return {
      modelName: 'medical_case',
      resourceRoute: 'medical_cases',
      listPath: '/medical_cases',
      locale: 'en',
      herbs: [],
      isLoading: false,
      model: {
        name: { ar: null, en: null },
        description: { ar: null, en: null },
        image: null,
        image_path: null,
        herbs: []
      }
    }
  },
  methods: {
    addHerb () {
      this.model.herbs.push({
        herb: null,
        desc: {
          'ar': null,
          'en': null
        },
        collapse_status: false
      })
    },
    dropHerb (index = 0) {
      this.model.herbs.splice(index, 1)
    },
    beforeSave () {
      this.model.herbs.forEach((h) => {
        h.collapse_status = true
      })
    }

  }
}
</script>

<style>
  .option__desc, .option__image {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 60%;
  }
</style>
