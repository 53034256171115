import Vue from 'vue'
import Vuex from 'vuex'

import { createActions } from './actions'
import mutations from './mutations'

Vue.use(Vuex)

export function createStore (route) {
  const actions = createActions(route)

  return new Vuex.Store({
    state: {
      counters: {
        advertisementsCount: 0,
        categoriesCount: 0,
        herbsCount: 0,
        linksCount: 0,
        medicalCasesCount: 0,
        medicalTreatmentsCount: 0,
        slidersCount: 0,
        tagsCount: 0,
        usersCount: 0,
        clientsCount: 0
      },
      user: null
    },
    actions,
    mutations,
    getters: {
      getUser: (state) => (state) => state.user
    }
  })
}
