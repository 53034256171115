import axios from 'axios'

export function createActions (route) {
  return {
    LOAD_COUNTERS: ({ commit }) => {
      return new Promise((resolve) => {
        axios.all([
          axios.get(route('ajax.get_counts'))
        ])
          .then(axios.spread(
            (
              counts
            ) => {
              commit('SET_COUNTER', { type: 'usersCount', counter: counts.data.usersCount })
              commit('SET_COUNTER', { type: 'clientsCount', counter: counts.data.clientsCount })
              commit('SET_COUNTER', { type: 'advertisementsCount', counter: counts.data.advertisementsCount })
              commit('SET_COUNTER', { type: 'categoriesCount', counter: counts.data.categoriesCount })
              commit('SET_COUNTER', { type: 'herbsCount', counter: counts.data.herbsCount })
              commit('SET_COUNTER', { type: 'linksCount', counter: counts.data.linksCount })
              commit('SET_COUNTER', { type: 'medicalCasesCount', counter: counts.data.medicalCasesCount })
              commit('SET_COUNTER', { type: 'medicalTreatmentsCount', counter: counts.data.medicalTreatmentsCount })
              commit('SET_COUNTER', { type: 'slidersCount', counter: counts.data.slidersCount })
              commit('SET_COUNTER', { type: 'tagsCount', counter: counts.data.tagsCount })
              commit('SET_COUNTER', { type: 'commentsCount', counter: counts.data.commentsCount })
              commit('SET_COUNTER', { type: 'approvedCommentsCount', counter: counts.data.approvedCommentsCount })
              commit('SET_COUNTER', { type: 'rejectedCommentsCount', counter: counts.data.rejectedCommentsCount })
              commit('SET_COUNTER', { type: 'pendingCommentsCount', counter: counts.data.pendingCommentsCount })
              resolve()
            }))
      })
    },
    GET_USER: ({ commit }) => {
      return new Promise((resolve) => {
        axios.get(route('session_status'))
          .then(
            (
              response
            ) => {
              commit('SET_USER', { user: response.data.user })

              resolve()
            })
      })
    }
  }
}
