<template>
  <div class="animated fadeIn">
    <form @submit.prevent="onSubmit">
      <b-row class="justify-content-center">
        <b-col xl="6">
          <b-card>
            <template slot="header">
              <h3 class="card-title">{{ isNew ? $t('labels.backend.sliders.titles.create') : $t('labels.backend.sliders.titles.edit') }}</h3>
            </template>
            <b-row class="justify-content-center">
              <b-col xl="12">
                <b-form-group
                  :label="$t('validation.attributes.language')"
                  label-for="language"
                  :label-cols="4"
                >
                  <b-form-radio-group
                    buttons button-variant="outline-primary"
                    v-model="model.language" :options="['ar','en']" name="language"
                  ></b-form-radio-group>
                </b-form-group>

                <target v-model="model.target" :with-external="true" :with-empty="true"></target>

                <b-form-group
                  :label="$t('validation.attributes.active')"
                  label-for="active"
                  :label-cols="4"
                  :invalid-feedback="feedback('active')"
                >
                  <c-switch
                    name="active"
                    v-model="model.active"
                    :description="$t('validation.attributes.active')"
                  ></c-switch>
                </b-form-group>

                <photo v-model="model.image" :label="$t('validation.attributes.image')" field_name="image" :image_path="model.image_path"></photo>

              </b-col>
            </b-row>

            <b-row slot="footer">
              <b-col md>
                <b-button to="/sliders" variant="danger" size="sm">
                  {{ $t('buttons.back') }}
                </b-button>
              </b-col>
              <b-col md>
                <b-button type="submit" variant="success" size="sm" class="float-right"
                          :disabled="pending"
                          v-if="(isNew && this.$app.user.can('create sliders')) || this.$app.user.can('edit sliders')">
                  {{ isNew ? $t('buttons.create') : $t('buttons.edit') }}
                </b-button>
              </b-col>
            </b-row>
            <atom-spinner
              :animation-duration="1000"
              :size="400"
              :color="'#d3e335'"
              :loading="loading"
              :full="true"
            ></atom-spinner>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import form from '../mixins/form'
import Photo from './components/Photo'
import Target from './components/Target'

export default {
  name: 'SliderForm',
  components: { Photo, Target },
  mixins: [form],
  data () {
    return {
      modelName: 'slider',
      resourceRoute: 'sliders',
      listPath: '/sliders',
      locale: 'en',
      model: {
        language: 'ar',
        target: {
          target: null,
          target_type: null,
          link: null
        },
        active: true,
        image: null,
        image_path: null
      }
    }
  }
}
</script>
