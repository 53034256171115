<template>
  <tr>
    <td>
      <b-form-group
        :invalid-feedback="feedback(herb_field)"
        :state="state(herb_field)"
      >
        <multiselect v-model="value.herb" :options="herbs"
                     id="herbs" label="herb_name" track-by="id"
                     :placeholder="'-- ' + $t('validation.attributes.herbs') + ' --'"
                     :searchable="true" :show-labels="false"
                     :loading="isLoading" :internal-search="false" :clear-on-select="true"
                     :show-no-results="false" @search-change="asyncFindHerb">
        </multiselect>
      </b-form-group>
    </td>
    <td>
      <b-form-group
        label-for="desc"
        :invalid-feedback="feedback(`${desc_field}.ar`) || feedback(`${desc_field}.en`)" v-if="locale==='ar'"
      >
        <rich-text-editor
          key="herb_desc_ar"
          name="herb_desc_ar"
          v-model="value.desc.ar"
          :minimum="true"
        ></rich-text-editor>
      </b-form-group>
      <b-form-group
        label-for="desc"
        :invalid-feedback="feedback(`${desc_field}.ar`) || feedback(`${desc_field}.en`)" v-if="locale==='en'"
      >
        <rich-text-editor
          key="herb_desc_en"
          name="herb_desc_en"
          v-model="value.desc.en"
          :minimum="true"
        ></rich-text-editor>

        <!--          <b-form-textarea-->
        <!--          id="desc" name="desc" :rows="2"-->
        <!--          :placeholder="$t('validation.attributes.description_en')"-->
        <!--          v-model="value.desc.en"-->
        <!--          :state="state(`${desc_field}.ar`) || state(`${desc_field}.en`)"-->
        <!--        ></b-form-textarea>-->
      </b-form-group>
    </td>
    <td>
      <b-button
        size="sm"
        variant="danger"
        v-b-tooltip.hover
        :title="$t('buttons.drop_herb')"
        @click="dropHerb()"
        v-if="!disabled"
      >
        <i class="fe fe-x"></i>
      </b-button>
    </td>
  </tr>
</template>
<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import RichTextEditor from '../../components/Plugins/RichTextEditor'

export default {
  name: 'HerbSelectorAlt',
  components: { Multiselect, RichTextEditor },
  props: {
    value: {
      default: () => ({
        herb: null,
        desc: {
          'ar': null,
          'en': null
        }
      }),
      type: Object
    },
    index: {
      default: 0,
      type: Number
    },
    locale: {
      default: 'en',
      type: String
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      herbs: [],
      isLoading: false,
      photo_url: null
    }
  },
  computed: {
    herb_field: function () {
      return 'herbs.' + this.index + '.herb'
    },
    desc_field: function () {
      return 'herbs.' + this.index + '.desc'
    }
  },
  methods: {
    updateInput: function () {
      this.$emit('input', {
        herb: this.value.herb,
        desc: {
          'ar': this.value.desc.ar,
          'en': this.value.desc.en
        }
      })
    },
    dropHerb () {
      this.$emit('deleted', { index: this.index })
    },
    feedback (name) {
      if (this.state(name)) {
        return this.$parent.validation.errors[name][0]
      }
    },
    state (name) {
      return this.$parent.validation.errors !== undefined &&
        this.$parent.validation.errors.hasOwnProperty(name)
        ? 'invalid'
        : null
    },
    async asyncFindHerb (query) {
      if (query.length <= 1) {
        this.herbs = []
        return
      }
      this.isLoading = true
      let params = {
        page: 1,
        perPage: 30
      }

      params.search = query

      let { data } = await axios.get(this.$app.route(`herbs.search`), {
        params: params
      })

      let d = data.data
      this.herbs = d.map((item) => {
        let herbName = ''
        if (item.name.ar && item.name.en) herbName = item.name.ar + ' (' + item.name.en + ')'
        else if (!item.name.ar) herbName = item.name.en
        else herbName = item.name.ar
        return {
          id: item.id,
          herb_name: herbName
        }
      })

      this.isLoading = false
    }

  }
}
</script>

<style scoped>
.btn-group >>> .btn.active {
  z-index: 0 !important;
}
.is-invalid >>> .multiselect__tags {
  border-color: #f86c6b !important;
}
.is-invalid >>> .form-control {
  border-color: #f86c6b !important;
}
.herb-selector {
    background-color: #f0fffd;
    padding-top: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 3px;
    border: 1px solid #e9ecef;
}
.herb-selector:hover {
    background-color: #e4fffd;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}
.close {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
