<template>
  <div class="animated fadeIn">
    <form @submit.prevent="onSubmit">
      <b-row class="justify-content-center">
        <b-col xl="6">
          <b-card>
            <template slot="header">
              <h3 class="card-title">{{ isNew ? $t('labels.backend.advertisements.titles.create') : $t('labels.backend.advertisements.titles.edit') }}</h3>
              <div class="card-options">
                <b-form-radio-group
                  buttons button-variant="outline-secondary" v-model="locale" :options="['ar','en']" name="locale"
                ></b-form-radio-group>
              </div>
            </template>
            <b-row class="justify-content-center">
              <b-col xl="12">
                <b-form-group
                  :label="$t('validation.attributes.type')"
                  :label-cols="4"
                  :invalid-feedback="feedback('type')"
                  :state="state('type')"
                >
                  <multiselect :options="['Banner', 'Popup', 'News']" id="type" name="type" v-model="model.type"
                               :close-on-select="true" :show-labels="false"
                               :placeholder="'-- ' + $t('validation.attributes.type') + ' --'"></multiselect>
                </b-form-group>
                <b-form-group
                  :label="$t('validation.attributes.title_ar')"
                  label-for="title"
                  :label-cols="4"
                  :invalid-feedback="feedback('title.ar') || feedback('title.en')" v-if="locale==='ar'"
                >
                  <b-form-input
                    id="title_ar"
                    name="title_ar"
                    :placeholder="$t('validation.attributes.title_ar')"
                    v-model="model.title.ar"
                    :state="states('title')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  :label="$t('validation.attributes.title_en')"
                  label-for="title"
                  :label-cols="4"
                  :invalid-feedback="feedback('title.ar') || feedback('title.en')" v-if="locale==='en'"
                >
                  <b-form-input
                    id="title_en"
                    name="title_en"
                    :placeholder="$t('validation.attributes.title_en')"
                    v-model="model.title.en"
                    :state="states('title')"
                  ></b-form-input>
                </b-form-group>
                <template v-if="model.type && model.type !== 'Banner'">
                  <b-form-group
                    :label="$t('validation.attributes.description_ar')"
                    label-for="description"
                    :label-cols="4"
                    :invalid-feedback="feedback('description.ar') || feedback('description.en')" v-if="locale==='ar'"
                  >
                    <b-form-textarea
                      id="description_ar" name="description" :rows="3"
                      :placeholder="$t('validation.attributes.description_ar')"
                      v-model="model.description.ar"
                      :state="states('description')"
                    ></b-form-textarea>
                  </b-form-group>
                  <b-form-group
                    :label="$t('validation.attributes.description_en')"
                    label-for="description"
                    :label-cols="4"
                    :invalid-feedback="feedback('description.ar') || feedback('description.en')" v-if="locale==='en'"
                  >
                    <b-form-textarea
                      id="description_en" name="description" :rows="3"
                      :placeholder="$t('validation.attributes.description_en')"
                      v-model="model.description.en"
                      :state="states('description')"
                    ></b-form-textarea>
                  </b-form-group>
                </template>

                <b-form-group
                  :label="$t('validation.attributes.active')"
                  label-for="active"
                  :label-cols="4"
                  :invalid-feedback="feedback('active')"
                >
                  <c-switch
                    name="active"
                    v-model="model.active"
                    :description="$t('validation.attributes.active')"
                  ></c-switch>
                </b-form-group>

                <target v-model="model.target" :with-external="true" v-if="model.type && model.type !== 'News'"></target>

                <photo v-model="model.image" :label="$t('validation.attributes.image')" field_name="image" :image_path="model.image_path"></photo>

              </b-col>
            </b-row>

            <b-row slot="footer">
              <b-col md>
                <b-button to="/advertisements" variant="danger" size="sm">
                  {{ $t('buttons.back') }}
                </b-button>
              </b-col>
              <b-col md>
                <b-button type="submit" variant="success" size="sm" class="float-right"
                          :disabled="pending"
                          v-if="(isNew && this.$app.user.can('create advertisements')) || this.$app.user.can('edit advertisements')">
                  {{ isNew ? $t('buttons.create') : $t('buttons.edit') }}
                </b-button>
              </b-col>
            </b-row>
            <atom-spinner
              :animation-duration="1000"
              :size="400"
              :color="'#d3e335'"
              :loading="loading"
              :full="true"
            ></atom-spinner>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import form from '../mixins/form'
import Photo from './components/Photo'
import Multiselect from 'vue-multiselect'
import Target from './components/Target'

export default {
  name: 'AdvertisementForm',
  components: { Photo, Multiselect, Target },
  mixins: [form],
  data () {
    return {
      modelName: 'advertisement',
      resourceRoute: 'advertisements',
      listPath: '/advertisements',
      locale: 'en',
      model: {
        type: null,
        active: true,
        title: { ar: null, en: null },
        description: { ar: null, en: null },
        target: {
          target: null,
          target_type: null,
          link: null
        },
        image: null,
        image_path: null
      }
    }
  }
}
</script>
