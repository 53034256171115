<template>
  <v-app id="keep">
    <v-app-bar
      app
      clipped-left
    >
      <span class="title ml-3 mr-5">
        <router-link :to="{name: 'frontend.herbs'}">
          <img src="../../../vendor/tabler/brand/logo.png" alt="logo">
        </router-link>
      </span>
      <v-text-field
        solo-inverted
        flat
        hide-details
        label="Search"
        prepend-inner-icon="search"
        v-model="search"
        @change="goToSearch"
      ></v-text-field>

      <v-spacer >
        <span class="title ml-3 mr-5">
          <a href="https://play.google.com/store/apps/details?id=com.e_steps.herbs&hl=en" target="_blank">
            <img src="../../../vendor/tabler/brand/google_play.png" width="150">
          </a>
        </span>
      </v-spacer>
    </v-app-bar>
    <v-content>
      <v-overlay v-if="herb === null" absolute color="green lighten-4">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-container v-else
                   class="fluid px-0"
      >
        <v-row>
          <v-col sm="7" offset="1">
            <div class="display-3 pb-3"> {{ herb.name }} </div>
          </v-col>
          <v-col sm="2">
            <v-chip color="info">  Family: {{ herb.family }} </v-chip>
          </v-col>
        </v-row>
        <b-row>
          <v-col sm="7" offset="1">
            <p class="display-1">Other names</p>
            <p class="body-1"> {{ herb.other_names }} </p>
            <p class="display-1">Used parts</p>
            <p class="body-1"> {{ herb.used_parts }} </p>
            <p class="display-1">Description</p>
            <p class="body-1" style="text-align: justify"> {{ herb.description }} </p>
          </v-col>
          <v-col>
            <v-col>
              <v-img :src="herb.image" max-height="300" max-width="300" sizes=""></v-img>
            </v-col>
          </v-col>
        </b-row>
        <v-row>
          <v-col sm="10" offset="1">
            <v-carousel
              height="400"
              show-arrows-on-hover
            >
              <v-carousel-item
                v-for="(slider,i) in herb.slider"
                :key="i"
                :src="slider"
                reverse-transition="fade-transition"
                transition="fade-transition"
              ></v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
        <b-row>
          <v-col sm="10" offset="1">
            <p class="display-1">Nutrition value</p>
            <p class="body-1" v-html="herb.chemical_composition.replace(new RegExp('\r?\n','g'), '<br >')"></p>
            <p class="display-1">Health Benefits</p>
            <p class="body-1" v-html="herb.medical_usages.replace(new RegExp('\r?\n','g'), '<br >')"></p>
            <p class="display-1">Other Uses</p>
            <p class="body-1" v-html="herb.other_usages.replace(new RegExp('\r?\n','g'), '<br >')"></p>
            <p class="display-1">Warnings</p>
            <p class="body-1" v-html="herb.warnings.replace(new RegExp('\r?\n','g'), '<br >')"></p>
          </v-col>
        </b-row>
        <v-row v-if="images.length > 0">
          <v-col cols="10" offset-sm="1">
            <v-card>
              <v-container fluid>
                <v-row>
                  <v-col
                    v-for="(image,i) in images"
                    :key="i"
                    class="d-flex child-flex"
                    cols="4"
                  >
                    <v-card flat tile class="d-flex">
                      <v-img
                        :src="image"
                        :lazy-src="image"
                        aspect-ratio="1"
                        class="grey lighten-2"
                        @click="index = i"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <gallery :images="images" :index="index" @close="index = null" v-if="images.length > 0"></gallery>
        <b-row>
          <v-col sm="10" offset="1">
            <p class="display-1">References</p>
            <ul>
              <li v-for="(link, i) in herb.links"
                  :key="i"><a :href="link.link" class="body-1">{{ link.title }}</a></li>
            </ul>
          </v-col>
        </b-row>
      </v-container>
    </v-content>
    <fe-footer></fe-footer>
  </v-app>
</template>
<script>
import axios from 'axios'
import gallery from 'vue-gallery'
import FeFooter from './FeFooter'

export default {
  name: 'Article',
  components: {
    FeFooter,
    gallery
  },
  props: {
    id: {
      type: Number | String,
      required: true
    }
  },
  data: () => ({
    show: false,
    herb: null,
    search: null,
    index: null
  }),
  computed: {
    images () {
      if (this.herb.gallery_images.length > 0) return this.herb.gallery_images.map((o) => o['url'])
      else return this.herb.slider
    }
  },
  async created () {
    let { data } = await axios.get(this.$app.route(`api.herbs.details`, { herb: this.id }), { params: { include: 'links' } })
    this.herb = data
  },
  mounted () {
    // console.log(this.$route.params)
  },
  methods: {
    goToSearch () {
      this.$router.push({ name: 'frontend.herbs', query: { search: this.search } })
    }
  }
}
</script>

<style scoped>
    /deep/ a:not([href]):not([tabindex]) {
        color: white !important;
    }
</style>
