import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faHome,
  faUsers,
  faUser,
  faShieldAlt,
  faFilter,
  faCheck,
  faListAlt,
  faUserPlus,
  faEdit,
  faTimes,
  faSearch,
  faUserShield,
  faTag,
  faList,
  faLink,
  faLeaf,
  faUserMd,
  faTv,
  faAd,
  faExternalLinkAlt,
  faMortarPestle,
  faEye,
  faUnlink,
  faSortDown,
  faUserTie,
  faCommentDots,
  faCheckCircle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faHome,
  faUser,
  faUsers,
  faShieldAlt,
  faFilter,
  faCheck,
  faListAlt,
  faUserPlus,
  faEdit,
  faUserPlus,
  faTimes,
  faSearch,
  faUserShield,
  faTag,
  faList,
  faLink,
  faLeaf,
  faUserMd,
  faTv,
  faAd,
  faExternalLinkAlt,
  faMortarPestle,
  faEye,
  faUnlink,
  faSortDown,
  faUserTie,
  faCommentDots,
  faCheckCircle,
  faTimesCircle
)
